@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'JetBrains Mono', 'monospace';
}
.page-loader {
  width: 100%;
  height: 100vh;
  background: #000000;
  z-index: 100000;
  position: fixed;
}

.loadingText2:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

.freemintLoading{
  font-size: 25px;
  margin-top: 3%;
  background: -webkit-linear-gradient(#1df739, #b3fe00, #b3fe00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  font-style: italic;
  font-weight: bold;
  text-align: center;
}

.maxAmount{
color: white;
}

.freemintfade{
  display: none;
}

.page-loader .loadLogo {
  color: #fea676;
  text-align: center;
  top: 30%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
}

.loadLogo img {
  width: 250px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-animation: zoominoutsinglefeatured 3s infinite;
          animation: zoominoutsinglefeatured 3s infinite;
}
.color1{
  background: -webkit-linear-gradient(#1df739, #b3fe00, #b3fe00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
 
}

.color2{
  color: #d6d6d6;
 }
.mint2 {
  background: radial-gradient(circle, rgba(0,0,0,0.46262254901960786) 0%, rgba(0,0,0,0.6951155462184874) 69%, rgba(0,0,0,0.835171568627451) 98%), url(/static/media/2.633b59da.gif) no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  overflow: hidden;
}

a{
  color: #00d6fc;
  text-decoration: none;
}

a:hover{
  color: #39d7f3;
  text-decoration: underline;
}

.videoBg {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.vidCont {
  object-fit: cover;
  width: 100%;
}

@media screen and (max-width: 499px) {

  .walletAndMint {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .mintP:hover {
    color: gold;
    cursor: pointer;
  }
  
  .btnRed1 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 25px;
   }
  
  .btnRed2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  :root {
    --primary: #4f5877;
    --secondary: hsl(224, 20%, 17%);
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
  .button-49-2,
  .button-49-2:after {
    width: 230px;
    height: 76px;
    line-height: 78px;
    font-size: 18px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: 0.5s ease-in;
  }
  
  .button-49-2:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49-2:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
    opacity: 0;
  }

  .button-49-2:hover span {
    display:none
  }
  
  .button-49-2:hover:before {
    content:"COMING SOON";
  }
  
  .button-49,
  .button-49:after {
    width: 230px;
    height: 76px;
    line-height: 78px;
    font-size: 18px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
  }
  
  @-webkit-keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 250px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  .stakePagesBtns {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 10vh;
    flex-flow: column nowrap;
  }

  .stakePagesBtns2 {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 25px;
  }
  
  
  .stakeMainPage {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .stakeMainPage2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/futuristic-gif-9.73bec76a.gif) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .csMAin {
    background: rgb(0,28,32);
    background: linear-gradient(100deg, rgba(0,28,32,1) 0%, rgba(0,24,27,1) 69%, rgba(0,28,32,1) 98%);
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .aboutPageMain {
    background: rgb(0,30,33);
    background: linear-gradient(355deg, rgba(0,30,33,1) 0%, rgba(0,0,0,1) 69%, rgba(0,42,47,1) 98%);
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .stripe-btn {
    --text-shadow-thickness: 0.05ch;
    --text-shadow-negative-thickness: calc(-1 * var(--text-shadow-thickness));
    position: relative;
    border: 2px solid var(--primary);
    font-size: 3rem;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    color: var(--primary);
    text-transform: uppercase;
    text-shadow:
      var(--text-shadow-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary);
    width: 280px;
    text-align: center;
  }
  
  .corners {
    --corner-dimension: 0.7ch;
    --corner-thickness: 3px;
    --corner-negative-thickness: calc(-1 * var(--corner-thickness));
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .corners>i {
    content: "";
    position: absolute;
    width: var(--corner-dimension);
    height: var(--corner-dimension);
  }
  
  .corners>i:nth-child(1) {
    top: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(2) {
    top: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(3) {
    bottom: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(4) {
    bottom: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .stripes {
    --stripes-position: 0.3ch;
    content: "";
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: calc(100% - var(--stripes-position) * 2);
    width: calc(100% - var(--stripes-position) * 2);
    top: var(--stripes-position);
    left: var(--stripes-position);
    overflow: hidden;
  }
  
  .stripes>i {
    content: "";
    height: calc(100% + 2ch);
    width: 0.5ch;
    background: var(--primary);
    opacity: 0.3;
    -webkit-transform: translateY(-1ch) rotate(37deg);
            transform: translateY(-1ch) rotate(37deg);
    transition: all 0.2s cubic-bezier(0.85, 0, 0.15, 1)
  }
  
  .stripes>i:nth-child(1) {
    transition-delay: 0.02s;
  }
  
  .stripes>i:nth-child(2) {
    transition-delay: 0.04s;
  }
  
  .stripes>i:nth-child(3) {
    transition-delay: 0.06s;
  }
  
  .stripes>i:nth-child(4) {
    transition-delay: 0.08s;
  }
  
  .stripes>i:nth-child(5) {
    transition-delay: 0.10s;
  }
  
  .stripes>i:nth-child(6) {
    transition-delay: 0.12s;
  }
  
  .stripes>i:nth-child(7) {
    transition-delay: 0.14s;
  }
  
  .stripes>i:nth-child(8) {
    transition-delay: 0.16s;
  }
  
  .stripes>i:nth-child(9) {
    transition-delay: 0.18s;
  }
  
  .stripes>i:nth-child(10) {
    transition-delay: 0.20s;
  }
  
  .stripes>i:nth-child(11) {
    transition-delay: 0.22s;
  }
  
  .stripes>i:nth-child(12) {
    transition-delay: 0.24s;
  }
  
  .stripes>i:nth-child(13) {
    transition-delay: 0.26s;
  }
  
  .stripes>i:nth-child(14) {
    transition-delay: 0.28s;
  }
  
  .stripes>i:nth-child(15) {
    transition-delay: 0.3s;
  }
  
  .stripes>i:nth-child(16) {
    transition-delay: 0.32s;
  }
  
  .stripes>i:nth-child(17) {
    transition-delay: 0.34s;
  }
  
  .stripes>i:nth-child(18) {
    transition-delay: 0.36s;
  }
  
  .stripes>i:nth-child(19) {
    transition-delay: 0.38s;
  }
  
  .stripes>i:nth-child(20) {
    transition-delay: 0.4s;
  }
  
  .stripe-btn:hover .stripes>i {
    opacity: 0.5;
  }
  
  .futuristic-button {
    /* Other styling for the button */
    display: inline-block;
    padding: 10px 20px;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.2s ease;
    background-color: #e0706c00;
    font-size: 13px;
  }
  
  .futuristic-button:hover {
    background-color: #ff0;
    box-shadow: 0 0 10px #ff0, 0 0 40px #ff0, 0 0 80px #ff0;
    color: #000;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  
  .announcement {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 40vh;
  }
  
  
  .announcementP1 {
    color: gray;
    font-size: 16px;
  }
  
  .announcementP2 {
    color: white;
    font-size: 15px;
  }
  
  /* Animation */
  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    color: gray;
    font-size: 13px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    -webkit-animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 108px;
    }
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 108px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-3 {
    top: 50%;
    color: rgb(0, 194, 219);
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
  
  .tw {
    margin-top: 40px;
  }
  
  .anim-typewriter3 {
    -webkit-animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 310px;
    }
  }
  
  @keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 310px;
    }
  }
  
  .lineP {
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .futuristic-text {
    /* Other styling for the text */
    position: relative;
    overflow: hidden;
  }
  
  .futuristic-text:before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #ff0 0%, #f00 100%);
  }
  
  @-webkit-keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  .futuristic-text:after {
    content: '|';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #ff0;
    -webkit-animation: typing 1s steps(40, end);
            animation: typing 1s steps(40, end);
  }
  
  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    letter-spacing: 1.8px;
    width: 170px;
    height: 50px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 15px;
  }
  
  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }
  
  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }
  
  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }
  
  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }
  
  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }
  
  .kave-btn:hover .kave-line:after {
    top: 0;
  }
  
  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .header {
    width: 350px;
    height: 162px;
  }
  
  .mintP {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 30px;
    color: white;
    font-size: 27px;
  }
  
  .pic {
    /* 
      width: 25%; 
      position: sticky;
      position: -webkit-sticky;
      left: 100%;
      margin-top: -30%;
      bottom: 0;
      animation: Slide_Left_Right 16.25s ease infinite, Opacity 16.25s ease infinite;*/
    -webkit-transform: rotate(-27deg);
            transform: rotate(-27deg);
    width: 40%;
    position: sticky;
    position: -webkit-sticky;
    margin-top: -30%;
    bottom: 0;
    margin-left: 85%;
    margin-top: -80%;
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  .back {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    z-index: 100000000;
  }
  
  .backDiv {
    z-index: 1000000000000000000;
  }
  
  .blur {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .v2Quote {
    text-align: center;
    color: white;
  
    border: 2px #facc15 solid;
    position: fixed;
    padding: 15px;
    font-size: 30px;
    top: 50%;
    left: 50%;
    z-index: 100000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  
  }
  
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: rgb(12, 17, 40);
    background: linear-gradient(99deg, rgba(12, 17, 40, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(10, 33, 23, 1) 94%);
  }
  
  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 30%;
  }
  
  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 200px;
    padding: 20px;
  
  }
  
  .homeBtn button:hover {
    margin-bottom: 2%;
  }
  
  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 2%;
  }
  
  .homeImages1 {
    -webkit-animation: Slide_Down1 6s ease infinite;
            animation: Slide_Down1 6s ease infinite;
  }
  
  .homeImages2 {
    -webkit-animation: Slide_Down2 5s ease infinite;
            animation: Slide_Down2 5s ease infinite;
  }
  
  @-webkit-keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @-webkit-keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  
  .right {
    width: 20%;
    margin-top: -70%;
  }
  
  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
  
  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
  }
  
  .emailSectionH {
    color: black;
  
    font-size: 20px;
    margin-bottom: 10px;
    color: #facc15;
  }
  
  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }
  
  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #a58503;
  }
  
  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    border: none;
    width: 150px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;
  }
  
  .step {
    color: white;
    font-size: 15px;
  }
  
  .connect2Mob {
    display: none;
  }
  
  .tableMobile {
    display: none;
  }
  
  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }
  
  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  
  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;
  
  }
  
  .view2 {
    margin-left: 5%;
    width: 20%;
  }
  
  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .view {
    margin-right: 5%;
  }
  
  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }
  
  .orders {
    margin-top: 1%;
  }
  
  
  
  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  
  }
  
  .hideTable {
    display: none;
  }
  
  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  
  .adminBtns button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  
  
  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }
  
  .btnsMainMob {
    display: none;
  }
  
  .stakeWC {
    background-color: gold;
    border-style: none;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .home:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakeWC:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .navBarStake {
    justify-content: center;
    margin-bottom: 2%;
     
  }
  
  .navBarStake2 {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }
  
  .v1 {
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
  
  
  .btns {
    z-index: 10000;
    width: 200px;
    background-color: #586285;
    color: white;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 20px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: ease-in 0.5s;
  }
  
  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns2 {
    width: 200px;
    color: white;
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #586285;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    transition: ease-in 0.5s;
  
  }
  
  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btns2:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }
  
  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .markBar {
     margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto; 
    padding: 15px;
    
  }
  
  .markBarCon {
    background: rgba(172, 172, 172, 0.158);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #60e1f8, #006c80);
    margin-top: 2%;
    margin-bottom: 2%;
    background: url(/static/media/Container.64f3b4d9.png) no-repeat center center fixed;
    background-size: cover;
  }
  
  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }
  
  .num {
    font-size: 17px;
    color: #bdf1f5;
    text-align: center;
  }
  
  .name {
    color: #bdf1f5;
    font-size: 15px;
    text-align: center;
  
  }
  
  .num2 {
    font-size: 16px;
    color: white;
  
    text-align: center;
  }
  
  .name2 {
    color: white;
    font-size: 15px;
    text-align: center;
    font-weight: 500;
  }
  
  .markBar img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }
  
  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/IuNL.e000bf3c.gif) no-repeat center center fixed;
    background-size: cover;
  }
  
  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  
  }
  
  .sticky {
    width: 20%;
  
    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }
  
  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .board {
    margin-top: 5%;
    margin-bottom: 100px;
    padding: 35px;
    border: #ffffff 4px solid;
    box-shadow: -10px 10px rgba(75, 75, 75, 0.842);
  
    margin-left: 1%;
    margin-right: 1%;
  
  }
  
  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  
  .nameStake {
    width: 50%;
  }
  
  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .boardImg {
    background-color: #ffb963;
  
  }
  
  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }
  
  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }
  
  .approveBtns1 {
    border: 4px solid;
    background-color: rgba(206, 8, 8, 0);
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 17px;
  
    width: 270px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  
  }
  
  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);
  
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
  
    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .rabbitImg {
    width: 100px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .rabbitImgLogo {
    width: 100px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
  
  }
  
  .rabbitImg2 {
    width: 100px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }
  
  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }
  
  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;
  
  }
  
  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
  
  .logo2 {
    width: 20%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
  
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
  
  
  .rmPic img {
    width: 100%;
    background-size: cover;
  }
  
  .rmMain {
    margin-top: 10%;
  }
  
  .unstakeImgM {
    display: none;
  }
  
  .formStake {
    z-index: 1;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .light {
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .mintMain {
    background: linear-gradient(100deg, rgba(11,18,32,1) 0%, rgba(0,7,20,0.7314523221397935) 69%, rgba(11,18,32,1) 98%), url(/static/media/grid_0.a399d903.png) no-repeat center center fixed;
    background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }
  
  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }
  
  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }
  
  
  @-webkit-keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }
  
  .mintDiv {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    margin-bottom: 70px;
    box-shadow: 0 0 20px #00d6fc;
    margin-top: -80px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
      }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  
  }
  
  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect{
    display: none;
  }
  
  .connectMobile {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
     margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 25px;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }
  
  .connectMobile div {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  #fontSize {
    font-size: 13px;
    color: white;
    z-index: 1;

  }
  
  #fontSize:hover {
    color: #00E6F6;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  #fontSizeActive {
    font-size: 13px;
    color: #00E6F6;
   }
  
  #fontSizeActive:hover {
    color: white;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    background-color: #4bbdff;
  }
  
  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    background-color: #4bbdff;
  
    cursor: pointer;
  
  }
  
  .btnfos-0-2-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    border: none;
    width: 200px;
    border-top-left-radius: 10px;
   }
  
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #004dff;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0   var(--c),
      calc(-1 * -0.08em)  .01em 2px #0004;
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }
  
  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }
  
  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }
  
  .button-92 {
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;
  
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #00FF00
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;
  
    /* border-left: #2d363600;
    border-right: #2d363600;*/
  }
  
  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-top: -70px;

  }
  
  .wallet2 {
    background-color: #d79f6000;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
  
  }
  
  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  
  }
  
  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  .wallet3::after {
    transition: all 0.8s
  }
  
  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: #00E6F6;
    padding-bottom: 5%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    position: static;
    margin-bottom: 5%;
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  
  
  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .images_show {
    width: 90%;
  }
  
  .hide {
    display: none;
  }
  
  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }
  

  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 55%;
  }
  
  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 30px;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .imagesAndBtn {
    justify-content: center;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }
  
  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  
  }
  
  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }
  
  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  
  .formMain1-1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }
  
  .ownNFT {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  
  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .discordB {
    display: none;
  }
  
  .medal {
    width: 35px;
    height: 35px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }
  
  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }
  
  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .boxWrapC {
    margin-top: 10%;
  }
  
  .mainTB {
    text-align: center;
    color: white;
  
    font-size: 50px;
  
  }
  
  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }
  
  .sT {
    text-align: center;
    color: white;
  
    font-size: 25px;
    margin-bottom: 1%;
  }
  
  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }
  
  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .footer2 img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 50px;
  
    cursor: pointer;
  }
  
  .stakeT {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  
    letter-spacing: 2px;
  }
  
  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .winnerT {
    text-align: center;
    color: #facc15;
  
    font-size: 50px;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
  }
  
  table {
    border-collapse: collapse;
    width: 80%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }
  
  .bg {
    background-color: #facc15;
    color: black;
  }
  
  
  
  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .imageSet img {
    width: 30%;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
  }
  
  .imageSet img:hover {
    width: 30%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .logoNFT {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 1;

  }
  
  .glitch {
    color: white;
    font-size: 45px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    margin-top: 40px;
  }
  
  @-webkit-keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  
  @keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  @-webkit-keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  
  @keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  .glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  .aboutPageMain2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .aboutP{
    color: rgb(197, 247, 247);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    text-align: justify;
    font-size: 14px;
  }

  .aboutDiv{
    color: rgb(197, 247, 247);
    width: 80%;
    color: #00d6fc;
    margin-bottom: 60px;
    text-align: justify;
    justify-content: flex-start;
    font-size: 14px;
  }
  
  
  .cMain{
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain img{
    width: 65px;
    height: 65px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain div{
    color: rgb(167, 167, 167);
    text-align: center;
    margin-top: -12px;
    margin-bottom: 30px;
    font-size: 15px;
  }
  
  .cLine{
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .beast{
    width: 280px;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 40px;
   }
  
  .price{
    text-align: center;
    color: rgb(0, 250, 167);
  }
  
  .nftminter2{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: #b8eff8;
    font-size: 40px;
  }
  
  .nftminter2Div{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .center{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .placeAndWL{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 130px;
    margin-top: -50px;    flex-flow: column nowrap;
    display: flex;
  }
  
  /* CSS */
  .button-89 {
    --b: 3px;   /* border thickness */
    --s: .45em; /* size of the corner */
    --color: #ffffff;
    
    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 18px;
  
    border: 0;
  
    -ms-user-select: none;
  
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }
  
  .button-89:hover,
  .button-89:focus-visible{
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }
  
  .button-89:active {
    background: var(--color);
    color: #fff;
  }
  
  input{
    border: white 2px solid;
    font-size: 18px;
    padding: 6px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.432);
    color: #00e1ff;
  }
  
  
  .px-app,
  .px-app *,
  .px-app *::before,
  .px-app *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  .px-glitch {
    text-align: center;
    color: white;
  }
  
  .px-glitchtext {
    font-size: 3rem;
    font-weight: 600;
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    display: block;
  }
  .px-glitchtext-anim {
    -webkit-animation: glitchtext 300ms linear infinite;
            animation: glitchtext 300ms linear infinite;
  }
  @-webkit-keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  @keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  
  .px-glitchsvg {
    font-size: 4rem;
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
  .px-glitchsvg-anim {
    -webkit-animation: glitchsvg 200ms none infinite;
            animation: glitchsvg 200ms none infinite;
  }
  @-webkit-keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  @keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  
  .csPage{
    margin-top: 20vh;
  }
  
  :root {
    --color-bg: #eaeaea;
    --color-loader: #222;
    --loader-size: 100px;
    --loader-speed: 500ms;
  }
  
  .loadMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .loader {
    --loader-height: calc(var(--loader-size) / 7);
    display: flex;
    position: relative;
  }
  .loader::before {
    content: '';
    position: absolute;
  }
  .loader::before,
  .loader > span {
    display: block;
    width: var(--loader-height);
    height: var(--loader-height);
    border-radius: 50%;
    background: var(--color-loader);
    background-color: #516466;
    -webkit-animation-name: to-right;
            animation-name: to-right;
    -webkit-animation-duration: var(--loader-speed);
            animation-duration: var(--loader-speed);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .loader > span:nth-child(even) {
    visibility: hidden;
  }
  .loader > span:first-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
  }
  .loader > span:last-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
    animation-direction: reverse;
  }
  
  @-webkit-keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  
  @keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  @-webkit-keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .connectMobile {
    display: none;
  }

  .walletAndMint {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .mintP:hover {
    color: gold;
    cursor: pointer;
  }
  
  .btnRed1 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 25px;
   }
  
  .btnRed2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  :root {
    --primary: #4f5877;
    --secondary: hsl(224, 20%, 17%);
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
  .button-49-2,
  .button-49-2:after {
    width: 260px;
    height: 76px;
    line-height: 78px;
    font-size: 18px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: 0.5s ease-in;
  }
  
  .button-49-2:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49-2:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
    opacity: 0;
  }

  .button-49-2:hover span {
    display:none
  }
  
  .button-49-2:hover:before {
    content:"COMING SOON";
  }
  
  .button-49,
  .button-49:after {
    width: 260px;
    height: 76px;
    line-height: 78px;
    font-size: 18px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
  }
  
  @-webkit-keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 250px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  .stakePagesBtns {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 10vh;
    flex-flow: column nowrap;
    
  }

  .stakePagesBtns2 {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 25px;
  }
  
  
  .stakeMainPage {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .stakeMainPage2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/futuristic-gif-9.73bec76a.gif) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .csMAin {
    background: rgb(0,28,32);
    background: linear-gradient(100deg, rgba(0,28,32,1) 0%, rgba(0,24,27,1) 69%, rgba(0,28,32,1) 98%);
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .aboutPageMain {
    background: rgb(0,30,33);
    background: linear-gradient(355deg, rgba(0,30,33,1) 0%, rgba(0,0,0,1) 69%, rgba(0,42,47,1) 98%);
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .stripe-btn {
    --text-shadow-thickness: 0.05ch;
    --text-shadow-negative-thickness: calc(-1 * var(--text-shadow-thickness));
    position: relative;
    border: 2px solid var(--primary);
    font-size: 3rem;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    color: var(--primary);
    text-transform: uppercase;
    text-shadow:
      var(--text-shadow-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary);
    width: 280px;
    text-align: center;
  }
  
  .corners {
    --corner-dimension: 0.7ch;
    --corner-thickness: 3px;
    --corner-negative-thickness: calc(-1 * var(--corner-thickness));
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .corners>i {
    content: "";
    position: absolute;
    width: var(--corner-dimension);
    height: var(--corner-dimension);
  }
  
  .corners>i:nth-child(1) {
    top: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(2) {
    top: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(3) {
    bottom: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(4) {
    bottom: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .stripes {
    --stripes-position: 0.3ch;
    content: "";
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: calc(100% - var(--stripes-position) * 2);
    width: calc(100% - var(--stripes-position) * 2);
    top: var(--stripes-position);
    left: var(--stripes-position);
    overflow: hidden;
  }
  
  .stripes>i {
    content: "";
    height: calc(100% + 2ch);
    width: 0.5ch;
    background: var(--primary);
    opacity: 0.3;
    -webkit-transform: translateY(-1ch) rotate(37deg);
            transform: translateY(-1ch) rotate(37deg);
    transition: all 0.2s cubic-bezier(0.85, 0, 0.15, 1)
  }
  
  .stripes>i:nth-child(1) {
    transition-delay: 0.02s;
  }
  
  .stripes>i:nth-child(2) {
    transition-delay: 0.04s;
  }
  
  .stripes>i:nth-child(3) {
    transition-delay: 0.06s;
  }
  
  .stripes>i:nth-child(4) {
    transition-delay: 0.08s;
  }
  
  .stripes>i:nth-child(5) {
    transition-delay: 0.10s;
  }
  
  .stripes>i:nth-child(6) {
    transition-delay: 0.12s;
  }
  
  .stripes>i:nth-child(7) {
    transition-delay: 0.14s;
  }
  
  .stripes>i:nth-child(8) {
    transition-delay: 0.16s;
  }
  
  .stripes>i:nth-child(9) {
    transition-delay: 0.18s;
  }
  
  .stripes>i:nth-child(10) {
    transition-delay: 0.20s;
  }
  
  .stripes>i:nth-child(11) {
    transition-delay: 0.22s;
  }
  
  .stripes>i:nth-child(12) {
    transition-delay: 0.24s;
  }
  
  .stripes>i:nth-child(13) {
    transition-delay: 0.26s;
  }
  
  .stripes>i:nth-child(14) {
    transition-delay: 0.28s;
  }
  
  .stripes>i:nth-child(15) {
    transition-delay: 0.3s;
  }
  
  .stripes>i:nth-child(16) {
    transition-delay: 0.32s;
  }
  
  .stripes>i:nth-child(17) {
    transition-delay: 0.34s;
  }
  
  .stripes>i:nth-child(18) {
    transition-delay: 0.36s;
  }
  
  .stripes>i:nth-child(19) {
    transition-delay: 0.38s;
  }
  
  .stripes>i:nth-child(20) {
    transition-delay: 0.4s;
  }
  
  .stripe-btn:hover .stripes>i {
    opacity: 0.5;
  }
  
  .futuristic-button {
    /* Other styling for the button */
    display: inline-block;
    padding: 10px 20px;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.2s ease;
    background-color: #e0706c00;
    font-size: 13px;
  }
  
  .futuristic-button:hover {
    background-color: #ff0;
    box-shadow: 0 0 10px #ff0, 0 0 40px #ff0, 0 0 80px #ff0;
    color: #000;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  
  .announcement {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 40vh;
  }
  
  
  .announcementP1 {
    color: gray;
    font-size: 16px;
  }
  
  .announcementP2 {
    color: white;
    font-size: 16px;
  }
  
  /* Animation */
  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    color: gray;
    font-size: 14px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    -webkit-animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 115px;
    }
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 115px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-3 {
    top: 50%;
    color: rgb(0, 194, 219);
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tw {
    margin-top: 40px;
  }
  
  .anim-typewriter3 {
    -webkit-animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 350px;
    }
  }
  
  @keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 350px;
    }
  }
  
  .lineP {
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .futuristic-text {
    /* Other styling for the text */
    position: relative;
    overflow: hidden;
  }
  
  .futuristic-text:before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #ff0 0%, #f00 100%);
  }
  
  @-webkit-keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  .futuristic-text:after {
    content: '|';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #ff0;
    -webkit-animation: typing 1s steps(40, end);
            animation: typing 1s steps(40, end);
  }
  
  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    letter-spacing: 1.8px;
    width: 170px;
    height: 50px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 15px;
  }
  
  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }
  
  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }
  
  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }
  
  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }
  
  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }
  
  .kave-btn:hover .kave-line:after {
    top: 0;
  }
  
  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .header {
    width: 350px;
    height: 162px;
  }
  
  .mintP {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 30px;
    color: white;
    font-size: 27px;
  }
  
  .pic {
    /* 
      width: 25%; 
      position: sticky;
      position: -webkit-sticky;
      left: 100%;
      margin-top: -30%;
      bottom: 0;
      animation: Slide_Left_Right 16.25s ease infinite, Opacity 16.25s ease infinite;*/
    -webkit-transform: rotate(-27deg);
            transform: rotate(-27deg);
    width: 40%;
    position: sticky;
    position: -webkit-sticky;
    margin-top: -30%;
    bottom: 0;
    margin-left: 85%;
    margin-top: -80%;
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  .back {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    z-index: 100000000;
  }
  
  .backDiv {
    z-index: 1000000000000000000;
  }
  
  .blur {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .v2Quote {
    text-align: center;
    color: white;
  
    border: 2px #facc15 solid;
    position: fixed;
    padding: 15px;
    font-size: 30px;
    top: 50%;
    left: 50%;
    z-index: 100000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  
  }
  
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: rgb(12, 17, 40);
    background: linear-gradient(99deg, rgba(12, 17, 40, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(10, 33, 23, 1) 94%);
  }
  
  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 30%;
  }
  
  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 200px;
    padding: 20px;
  
  }
  
  .homeBtn button:hover {
    margin-bottom: 2%;
  }
  
  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 2%;
  }
  
  .homeImages1 {
    -webkit-animation: Slide_Down1 6s ease infinite;
            animation: Slide_Down1 6s ease infinite;
  }
  
  .homeImages2 {
    -webkit-animation: Slide_Down2 5s ease infinite;
            animation: Slide_Down2 5s ease infinite;
  }
  
  @-webkit-keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @-webkit-keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  
  .right {
    width: 20%;
    margin-top: -70%;
  }
  
  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
  
  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
  }
  
  .emailSectionH {
    color: black;
  
    font-size: 20px;
    margin-bottom: 10px;
    color: #facc15;
  }
  
  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }
  
  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #a58503;
  }
  
  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    border: none;
    width: 150px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;
  }
  
  .step {
    color: white;
    font-size: 15px;
  }
  
  .connect2Mob {
    display: none;
  }
  
  .tableMobile {
    display: none;
  }
  
  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }
  
  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  
  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;
  
  }
  
  .view2 {
    margin-left: 5%;
    width: 20%;
  }
  
  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .view {
    margin-right: 5%;
  }
  
  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }
  
  .orders {
    margin-top: 1%;
  }
  
  
  
  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  
  }
  
  .hideTable {
    display: none;
  }
  
  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  
  .adminBtns button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  
  
  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }
  
  .btnsMainMob {
    display: none;
  }
  
  .stakeWC {
    background-color: gold;
    border-style: none;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .home:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakeWC:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .navBarStake {
    justify-content: center;
    margin-bottom: 2%;
  
  }
  
  .navBarStake2 {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }
  
  .v1 {
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
  
  
  .btns {
    z-index: 10000;
    width: 200px;
    background-color: #586285;
    color: white;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 20px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: ease-in 0.5s;
  }
  
  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns2 {
    width: 200px;
    color: white;
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #586285;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    transition: ease-in 0.5s;
  
  }
  
  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btns2:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }
  
  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .markBar {
     margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto; 
    padding: 15px;
    
  }
  
  .markBarCon {
    background: rgba(172, 172, 172, 0.158);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #60e1f8, #006c80);
    margin-top: 2%;
    margin-bottom: 2%;
    background: url(/static/media/Container.64f3b4d9.png) no-repeat center center fixed;
    background-size: cover;
  }
  
  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }
  
  .num {
    font-size: 17px;
    color: #bdf1f5;
    text-align: center;
  }
  
  .name {
    color: #bdf1f5;
    font-size: 15px;
    text-align: center;
  
  }
  
  .num2 {
    font-size: 20px;
    color: white;
  
    text-align: center;
  }
  
  .name2 {
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }
  
  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }
  
  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/IuNL.e000bf3c.gif) no-repeat center center fixed;
    background-size: cover;
  }
  
  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  
  }
  
  .sticky {
    width: 20%;
  
    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }
  
  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .board {
    margin-top: 5%;
    margin-bottom: 100px;
    padding: 35px;
    border: #ffffff 4px solid;
    box-shadow: -10px 10px rgba(75, 75, 75, 0.842);
  
    margin-left: 1%;
    margin-right: 1%;
  
  }
  
  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  
  .nameStake {
    width: 50%;
  }
  
  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .boardImg {
    background-color: #ffb963;
  
  }
  
  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }
  
  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }
  
  .approveBtns1 {
    border: 4px solid;
    background-color: rgba(206, 8, 8, 0);
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 18px;
  
    width: 350px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  
  }
  
  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);
  
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
  
    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .rabbitImg {
    width: 100px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .rabbitImgLogo {
    width: 100px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
  
  }
  
  .rabbitImg2 {
    width: 100px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }
  
  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }
  
  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;
  
  }
  
  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
  
  .logo2 {
    width: 20%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
  
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
  
  
  .rmPic img {
    width: 100%;
    background-size: cover;
  }
  
  .rmMain {
    margin-top: 10%;
  }
  
  .unstakeImgM {
    display: none;
  }
  
  .formStake {
    z-index: 1;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .light {
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .mintMain {
    background: linear-gradient(100deg, rgba(11,18,32,1) 0%, rgba(0,7,20,0.7314523221397935) 69%, rgba(11,18,32,1) 98%), url(/static/media/grid_0.a399d903.png) no-repeat center center fixed;
    background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }
  
  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }
  
  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }
  
  
  @-webkit-keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }
  
  .mintDiv {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    margin-bottom: 70px;
    box-shadow: 0 0 20px #00d6fc;
    margin-top: -80px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
      }
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  
  }
  
  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
     margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 25px;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }
  
  .connect div {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  #fontSize {
    font-size: 13px;
    color: white;
    z-index: 1;

  }
  
  #fontSize:hover {
    color: #00E6F6;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  #fontSizeActive {
    font-size: 13px;
    color: #00E6F6;
   }
  
  #fontSizeActive:hover {
    color: white;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    background-color: #4bbdff;
  }
  
  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    background-color: #4bbdff;
  
    cursor: pointer;
  
  }
  
  .btnfos-0-2-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    border: none;
    width: 200px;
    border-top-left-radius: 10px;
   }
  
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #004dff;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0   var(--c),
      calc(-1 * -0.08em)  .01em 2px #0004;
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }
  
  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }
  
  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }
  
  .button-92 {
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;
  
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #00FF00
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;
  
    /* border-left: #2d363600;
    border-right: #2d363600;*/
  }
  
  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-top: -70px;

  }
  
  .wallet2 {
    background-color: #d79f6000;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
  
  }
  
  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 290px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  
  }
  
  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  .wallet3::after {
    transition: all 0.8s
  }
  
  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: #00E6F6;
    padding-bottom: 5%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    position: static;
    margin-bottom: 5%;
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  
  
  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .images_show {
    width: 90%;
 
  }
  
  .hide {
    display: none;
  }
  
  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }
  

  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40%;
  }
  
  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .imagesAndBtn {
    justify-content: center;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }
  
  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  
  }
  
  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }
  
  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  
  .formMain1-1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }
  
  .ownNFT {
     margin-bottom: 80px;
     margin-top: 80px;
  }
  
  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
  
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .discordB {
    display: none;
  }
  
  .medal {
    width: 35px;
    height: 35px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }
  
  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }
  
  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .boxWrapC {
    margin-top: 10%;
  }
  
  .mainTB {
    text-align: center;
    color: white;
  
    font-size: 50px;
  
  }
  
  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }
  
  .sT {
    text-align: center;
    color: white;
  
    font-size: 25px;
    margin-bottom: 1%;
  }
  
  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }
  
  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .footer2 img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 50px;
  
    cursor: pointer;
  }
  
  .stakeT {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  
    letter-spacing: 2px;
  }
  
  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .winnerT {
    text-align: center;
    color: #facc15;
  
    font-size: 50px;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
  }
  
  table {
    border-collapse: collapse;
    width: 80%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }
  
  .bg {
    background-color: #facc15;
    color: black;
  }
  
  
  
  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .imageSet img {
    width: 30%;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    
  }
  
  .imageSet img:hover {
    width: 30%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    
  }
  
  .logoNFT {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 1;

  }
  
  .glitch {
    color: white;
    font-size: 60px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    margin-top: 40px;
  }
  
  @-webkit-keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  
  @keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  @-webkit-keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  
  @keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  .glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  .aboutPageMain2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .aboutP{
    color: rgb(197, 247, 247);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    text-align: justify;
    font-size: 15px;
  }

  .aboutDiv{
    color: rgb(197, 247, 247);
    width: 80%;
    color: #00d6fc;
    margin-bottom: 60px;
    text-align: justify;
    justify-content: flex-start;
    font-size: 15px
  }
  
  
  .cMain{
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain img{
    width: 65px;
    height: 65px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain div{
    color: rgb(167, 167, 167);
    text-align: center;
    margin-top: -12px;
    margin-bottom: 30px;
    font-size: 15px;
  }
  
  .cLine{
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .beast{
    width: 330px;
    height: 330px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
   }
  
  .price{
    text-align: center;
    color: rgb(0, 250, 167);
  }
  
  .nftminter2{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: #b8eff8;
    font-size: 40px;
  }
  
  .nftminter2Div{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .center{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .placeAndWL{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 130px;
    margin-top: -50px;    flex-flow: column nowrap;
    display: flex;
  }
  
  /* CSS */
  .button-89 {
    --b: 3px;   /* border thickness */
    --s: .45em; /* size of the corner */
    --color: #ffffff;
    
    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 16px;
  
    border: 0;
  
    -ms-user-select: none;
  
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }
  
  .button-89:hover,
  .button-89:focus-visible{
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }
  
  .button-89:active {
    background: var(--color);
    color: #fff;
  }
  
  input{
    border: white 2px solid;
    font-size: 16px;
    padding: 6px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.432);
    color: #00e1ff;
  }
  
  
  .px-app,
  .px-app *,
  .px-app *::before,
  .px-app *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  .px-glitch {
    text-align: center;
    color: white;
  }
  
  .px-glitchtext {
    font-size: 4rem;
    font-weight: 600;
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    display: block;
  }
  .px-glitchtext-anim {
    -webkit-animation: glitchtext 300ms linear infinite;
            animation: glitchtext 300ms linear infinite;
  }
  @-webkit-keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  @keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  
  .px-glitchsvg {
    font-size: 4rem;
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
  .px-glitchsvg-anim {
    -webkit-animation: glitchsvg 200ms none infinite;
            animation: glitchsvg 200ms none infinite;
  }
  @-webkit-keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  @keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  
  .csPage{
    margin-top: 20vh;
  }
  
  :root {
    --color-bg: #eaeaea;
    --color-loader: #222;
    --loader-size: 100px;
    --loader-speed: 500ms;
  }
  
  .loadMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .loader {
    --loader-height: calc(var(--loader-size) / 7);
    display: flex;
    position: relative;
  }
  .loader::before {
    content: '';
    position: absolute;
  }
  .loader::before,
  .loader > span {
    display: block;
    width: var(--loader-height);
    height: var(--loader-height);
    border-radius: 50%;
    background: var(--color-loader);
    background-color: #516466;
    -webkit-animation-name: to-right;
            animation-name: to-right;
    -webkit-animation-duration: var(--loader-speed);
            animation-duration: var(--loader-speed);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .loader > span:nth-child(even) {
    visibility: hidden;
  }
  .loader > span:first-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
  }
  .loader > span:last-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
    animation-direction: reverse;
  }
  
  @-webkit-keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  
  @keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  @-webkit-keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .connectMobile {
    display: none;
  }
  .walletAndMint {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .mintP:hover {
    color: gold;
    cursor: pointer;
  }
  
  .btnRed1 {
    margin-right: 40px;
  }
  
  .btnRed2 {
    margin-left: 40px;
  }
  
  :root {
    --primary: #4f5877;
    --secondary: hsl(224, 20%, 17%);
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
  .button-49-2,
  .button-49-2:after {
    width: 280px;
    height: 76px;
    line-height: 78px;
    font-size: 20px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: 0.5s ease-in;
  }
  
  .button-49-2:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49-2:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
    opacity: 0;
  }

  .button-49-2:hover span {
    display:none
  }
  
  .button-49-2:hover:before {
    content:"COMING SOON";
  }
  
  .button-49,
  .button-49:after {
    width: 280px;
    height: 76px;
    line-height: 78px;
    font-size: 20px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
  }
  
  @-webkit-keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 250px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  .stakePagesBtns {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 12vh;
  }

  .stakePagesBtns2 {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 50px;
  }
  
  
  .stakeMainPage {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .stakeMainPage2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/futuristic-gif-9.73bec76a.gif) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .csMAin {
    background: rgb(0,28,32);
    background: linear-gradient(100deg, rgba(0,28,32,1) 0%, rgba(0,24,27,1) 69%, rgba(0,28,32,1) 98%);
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .aboutPageMain {
    background: rgb(0,30,33);
    background: linear-gradient(355deg, rgba(0,30,33,1) 0%, rgba(0,0,0,1) 69%, rgba(0,42,47,1) 98%);
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .stripe-btn {
    --text-shadow-thickness: 0.05ch;
    --text-shadow-negative-thickness: calc(-1 * var(--text-shadow-thickness));
    position: relative;
    border: 2px solid var(--primary);
    font-size: 3rem;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    color: var(--primary);
    text-transform: uppercase;
    text-shadow:
      var(--text-shadow-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary);
    width: 280px;
    text-align: center;
  }
  
  .corners {
    --corner-dimension: 0.7ch;
    --corner-thickness: 3px;
    --corner-negative-thickness: calc(-1 * var(--corner-thickness));
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .corners>i {
    content: "";
    position: absolute;
    width: var(--corner-dimension);
    height: var(--corner-dimension);
  }
  
  .corners>i:nth-child(1) {
    top: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(2) {
    top: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(3) {
    bottom: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(4) {
    bottom: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .stripes {
    --stripes-position: 0.3ch;
    content: "";
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: calc(100% - var(--stripes-position) * 2);
    width: calc(100% - var(--stripes-position) * 2);
    top: var(--stripes-position);
    left: var(--stripes-position);
    overflow: hidden;
  }
  
  .stripes>i {
    content: "";
    height: calc(100% + 2ch);
    width: 0.5ch;
    background: var(--primary);
    opacity: 0.3;
    -webkit-transform: translateY(-1ch) rotate(37deg);
            transform: translateY(-1ch) rotate(37deg);
    transition: all 0.2s cubic-bezier(0.85, 0, 0.15, 1)
  }
  
  .stripes>i:nth-child(1) {
    transition-delay: 0.02s;
  }
  
  .stripes>i:nth-child(2) {
    transition-delay: 0.04s;
  }
  
  .stripes>i:nth-child(3) {
    transition-delay: 0.06s;
  }
  
  .stripes>i:nth-child(4) {
    transition-delay: 0.08s;
  }
  
  .stripes>i:nth-child(5) {
    transition-delay: 0.10s;
  }
  
  .stripes>i:nth-child(6) {
    transition-delay: 0.12s;
  }
  
  .stripes>i:nth-child(7) {
    transition-delay: 0.14s;
  }
  
  .stripes>i:nth-child(8) {
    transition-delay: 0.16s;
  }
  
  .stripes>i:nth-child(9) {
    transition-delay: 0.18s;
  }
  
  .stripes>i:nth-child(10) {
    transition-delay: 0.20s;
  }
  
  .stripes>i:nth-child(11) {
    transition-delay: 0.22s;
  }
  
  .stripes>i:nth-child(12) {
    transition-delay: 0.24s;
  }
  
  .stripes>i:nth-child(13) {
    transition-delay: 0.26s;
  }
  
  .stripes>i:nth-child(14) {
    transition-delay: 0.28s;
  }
  
  .stripes>i:nth-child(15) {
    transition-delay: 0.3s;
  }
  
  .stripes>i:nth-child(16) {
    transition-delay: 0.32s;
  }
  
  .stripes>i:nth-child(17) {
    transition-delay: 0.34s;
  }
  
  .stripes>i:nth-child(18) {
    transition-delay: 0.36s;
  }
  
  .stripes>i:nth-child(19) {
    transition-delay: 0.38s;
  }
  
  .stripes>i:nth-child(20) {
    transition-delay: 0.4s;
  }
  
  .stripe-btn:hover .stripes>i {
    opacity: 0.5;
  }
  
  .futuristic-button {
    /* Other styling for the button */
    display: inline-block;
    padding: 10px 20px;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.2s ease;
    background-color: #e0706c00;
  }
  
  .futuristic-button:hover {
    background-color: #ff0;
    box-shadow: 0 0 10px #ff0, 0 0 40px #ff0, 0 0 80px #ff0;
    color: #000;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  
  .announcement {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 30vh;
  }
  
  
  .announcementP1 {
    color: gray;
    font-size: 16px;
  }
  
  .announcementP2 {
    color: white;
    font-size: 20px;
  }
  
  /* Animation */
  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    color: gray;
    font-size: 15px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    -webkit-animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 122px;
    }
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 122px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-3 {
    top: 50%;
    color: rgb(0, 194, 219);
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tw {
    margin-top: 40px;
  }
  
  .anim-typewriter3 {
    -webkit-animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 350px;
    }
  }
  
  @keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 350px;
    }
  }
  
  .lineP {
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .futuristic-text {
    /* Other styling for the text */
    position: relative;
    overflow: hidden;
  }
  
  .futuristic-text:before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #ff0 0%, #f00 100%);
  }
  
  @-webkit-keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  .futuristic-text:after {
    content: '|';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #ff0;
    -webkit-animation: typing 1s steps(40, end);
            animation: typing 1s steps(40, end);
  }
  
  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    letter-spacing: 1.8px;
    width: 170px;
    height: 50px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 16px;
  }
  
  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }
  
  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }
  
  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }
  
  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }
  
  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }
  
  .kave-btn:hover .kave-line:after {
    top: 0;
  }
  
  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .header {
    width: 350px;
    height: 162px;
  }
  
  .mintP {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 30px;
    color: white;
    font-size: 27px;
  }
  
  .pic {
    /* 
      width: 25%; 
      position: sticky;
      position: -webkit-sticky;
      left: 100%;
      margin-top: -30%;
      bottom: 0;
      animation: Slide_Left_Right 16.25s ease infinite, Opacity 16.25s ease infinite;*/
    -webkit-transform: rotate(-27deg);
            transform: rotate(-27deg);
    width: 40%;
    position: sticky;
    position: -webkit-sticky;
    margin-top: -30%;
    bottom: 0;
    margin-left: 85%;
    margin-top: -80%;
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  .back {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    z-index: 100000000;
  }
  
  .backDiv {
    z-index: 1000000000000000000;
  }
  
  .blur {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .v2Quote {
    text-align: center;
    color: white;
  
    border: 2px #facc15 solid;
    position: fixed;
    padding: 15px;
    font-size: 30px;
    top: 50%;
    left: 50%;
    z-index: 100000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  
  }
  
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: rgb(12, 17, 40);
    background: linear-gradient(99deg, rgba(12, 17, 40, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(10, 33, 23, 1) 94%);
  }
  
  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 30%;
  }
  
  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 200px;
    padding: 20px;
  
  }
  
  .homeBtn button:hover {
    margin-bottom: 2%;
  }
  
  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 2%;
  }
  
  .homeImages1 {
    -webkit-animation: Slide_Down1 6s ease infinite;
            animation: Slide_Down1 6s ease infinite;
  }
  
  .homeImages2 {
    -webkit-animation: Slide_Down2 5s ease infinite;
            animation: Slide_Down2 5s ease infinite;
  }
  
  @-webkit-keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @-webkit-keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  
  .right {
    width: 20%;
    margin-top: -70%;
  }
  
  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
  
  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
  }
  
  .emailSectionH {
    color: black;
  
    font-size: 20px;
    margin-bottom: 10px;
    color: #facc15;
  }
  
  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }
  
  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #a58503;
  }
  
  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    border: none;
    width: 150px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;
  }
  
  .step {
    color: white;
    font-size: 15px;
  }
  
  .connect2Mob {
    display: none;
  }
  
  .tableMobile {
    display: none;
  }
  
  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }
  
  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  
  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;
  
  }
  
  .view2 {
    margin-left: 5%;
    width: 20%;
  }
  
  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .view {
    margin-right: 5%;
  }
  
  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }
  
  .orders {
    margin-top: 1%;
  }
  
  
  
  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  
  }
  
  .hideTable {
    display: none;
  }
  
  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  
  .adminBtns button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  
  
  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }
  
  .btnsMainMob {
    display: none;
  }
  
  .stakeWC {
    background-color: gold;
    border-style: none;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .home:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakeWC:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .navBarStake {
    justify-content: center;
    margin-bottom: 2%;
  
  }
  
  .navBarStake2 {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }
  
  .v1 {
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
  
  
  .btns {
    z-index: 10000;
    width: 200px;
    background-color: #586285;
    color: white;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 20px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: ease-in 0.5s;
  }
  
  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns2 {
    width: 200px;
    color: white;
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #586285;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    transition: ease-in 0.5s;
  
  }
  
  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btns2:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }
  
  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .markBar {
     margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto; 
    padding: 15px;
    
  }
  
  .markBarCon {
    background: rgba(172, 172, 172, 0.158);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #60e1f8, #006c80);
    margin-top: 2%;
    margin-bottom: 2%;
    background: url(/static/media/Container.64f3b4d9.png) no-repeat center center fixed;
    background-size: cover;
  }
  
  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }
  
  .num {
    font-size: 17px;
    color: #bdf1f5;
    text-align: center;
  }
  
  .name {
    color: #bdf1f5;
    font-size: 15px;
    text-align: center;
  
  }
  
  .num2 {
    font-size: 20px;
    color: white;
  
    text-align: center;
  }
  
  .name2 {
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }
  
  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }
  
  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/IuNL.e000bf3c.gif) no-repeat center center fixed;
    background-size: cover;
  }
  
  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  
  }
  
  .sticky {
    width: 20%;
  
    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }
  
  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .board {
    margin-top: 5%;
    margin-bottom: 100px;
    padding: 35px;
    border: #ffffff 4px solid;
    box-shadow: -10px 10px rgba(75, 75, 75, 0.842);
  
    margin-left: 1%;
    margin-right: 1%;
  
  }
  
  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  
  .nameStake {
    width: 50%;
  }
  
  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .boardImg {
    background-color: #ffb963;
  
  }
  
  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }
  
  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }
  
  .approveBtns1 {
    border: 4px solid;
    background-color: rgba(206, 8, 8, 0);
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 18px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  
  }
  
  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);
  
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
  
    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
  
  }
  
  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }
  
  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }
  
  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;
  
  }
  
  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
  
  .logo2 {
    width: 20%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
  
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
  
  
  .rmPic img {
    width: 100%;
    background-size: cover;
  }
  
  .rmMain {
    margin-top: 10%;
  }
  
  .unstakeImgM {
    display: none;
  }
  
  .formStake {
    z-index: 1;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .light {
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .mintMain {
    background: linear-gradient(100deg, rgba(11,18,32,1) 0%, rgba(0,7,20,0.7314523221397935) 69%, rgba(11,18,32,1) 98%), url(/static/media/grid_0.a399d903.png) no-repeat center center fixed;
    background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }
  
  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }
  
  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }
  
  
  @-webkit-keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 470px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    margin-bottom: 70px;
    box-shadow: 0 0 20px #00d6fc;
    margin-top: -80px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
      }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  
  }
  
  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
     margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 25px;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  #fontSize {
    font-size: 16px;
    color: white;
    z-index: 1;

  }
  
  #fontSize:hover {
    color: #00E6F6;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  #fontSizeActive {
    font-size: 16px;
    color: #00E6F6;
   }
  
  #fontSizeActive:hover {
    color: white;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    background-color: #4bbdff;
  }
  
  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    background-color: #4bbdff;
  
    cursor: pointer;
  
  }
  
  .btnfos-0-2-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    border: none;
    width: 200px;
    border-top-left-radius: 10px;
   }
  
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #004dff;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0   var(--c),
      calc(-1 * -0.08em)  .01em 2px #0004;
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }
  
  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }
  
  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }
  
  .button-92 {
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;
  
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #00FF00
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;
  
    /* border-left: #2d363600;
    border-right: #2d363600;*/
  }
  
  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-top: -70px;

  }
  
  .wallet2 {
    background-color: #d79f6000;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
  
  }
  
  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 290px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  
  }
  
  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  .wallet3::after {
    transition: all 0.8s
  }
  
  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: #00E6F6;
    padding-bottom: 5%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    position: static;
    margin-bottom: 5%;
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  
  
  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .images_show {
    width: 90%;
  }
  
  .hide {
    display: none;
  }
  
  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }
  

  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25%;
  }
  
  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .imagesAndBtn {
    justify-content: center;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }
  
  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  
  }
  
  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }
  
  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  
  .formMain1-1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }
  
  .ownNFT {
    margin-bottom: 80px;
    margin-top: 80px;
  }
  
  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
  
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .discordB {
    display: none;
  }
  
  .medal {
    width: 35px;
    height: 35px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }
  
  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }
  
  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .boxWrapC {
    margin-top: 10%;
  }
  
  .mainTB {
    text-align: center;
    color: white;
  
    font-size: 50px;
  
  }
  
  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }
  
  .sT {
    text-align: center;
    color: white;
  
    font-size: 25px;
    margin-bottom: 1%;
  }
  
  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }
  
  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .footer2 img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 50px;
  
    cursor: pointer;
  }
  
  .stakeT {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  
    letter-spacing: 2px;
  }
  
  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .winnerT {
    text-align: center;
    color: #facc15;
  
    font-size: 50px;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
  }
  
  table {
    border-collapse: collapse;
    width: 80%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }
  
  .bg {
    background-color: #facc15;
    color: black;
  }
  
  
  
  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .imageSet img {
    width: 30%;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
  }
  
  .imageSet img:hover {
    width: 30%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .logoNFT {
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 1;

  }
  
  .glitch {
    color: white;
    font-size: 80px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  
  @-webkit-keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  
  @keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  @-webkit-keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  
  @keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  .glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  .aboutPageMain2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .aboutP{
    color: rgb(197, 247, 247);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    text-align: justify;
    font-size: 16px;
  }

  .aboutDiv{
    color: rgb(197, 247, 247);
    width: 80%;
    color: #00d6fc;
    margin-bottom: 60px;
    text-align: justify;
    justify-content: flex-start;
    font-size: 16px;

  }
  
  .cMain{
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain img{
    width: 65px;
    height: 65px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain div{
    color: rgb(167, 167, 167);
    text-align: center;
    margin-top: -12px;
    margin-bottom: 30px;
    font-size: 15px;
  }
  
  .cLine{
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .beast{
    width: 330px;
    height: 330px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
   }
  
  .price{
    text-align: center;
    color: rgb(0, 250, 167);
  }
  
  .nftminter2{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: #b8eff8;
    font-size: 40px;
  }
  
  .nftminter2Div{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .center{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .placeAndWL{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 130px;
    margin-top: -50px;  }
  
  /* CSS */
  .button-89 {
    --b: 3px;   /* border thickness */
    --s: .45em; /* size of the corner */
    --color: #ffffff;
    
    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 16px;
  
    border: 0;
  
    -ms-user-select: none;
  
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-89:hover,
  .button-89:focus-visible{
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }
  
  .button-89:active {
    background: var(--color);
    color: #fff;
  }
  
  input{
    border: white 2px solid;
    font-size: 16px;
    padding: 6px;
    margin-right: 20px;
    background-color: rgba(0, 0, 0, 0.432);
    color: #00e1ff;
  }
  
  
  .px-app,
  .px-app *,
  .px-app *::before,
  .px-app *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  .px-glitch {
    text-align: center;
    color: white;
  }
  
  .px-glitchtext {
    font-size: 4rem;
    font-weight: 600;
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    display: block;
  }
  .px-glitchtext-anim {
    -webkit-animation: glitchtext 300ms linear infinite;
            animation: glitchtext 300ms linear infinite;
  }
  @-webkit-keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  @keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  
  .px-glitchsvg {
    font-size: 4rem;
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
  .px-glitchsvg-anim {
    -webkit-animation: glitchsvg 200ms none infinite;
            animation: glitchsvg 200ms none infinite;
  }
  @-webkit-keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  @keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  
  .csPage{
    margin-top: 20vh;
  }
  
  :root {
    --color-bg: #eaeaea;
    --color-loader: #222;
    --loader-size: 100px;
    --loader-speed: 500ms;
  }
  
  .loadMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .loader {
    --loader-height: calc(var(--loader-size) / 7);
    display: flex;
    position: relative;
  }
  .loader::before {
    content: '';
    position: absolute;
  }
  .loader::before,
  .loader > span {
    display: block;
    width: var(--loader-height);
    height: var(--loader-height);
    border-radius: 50%;
    background: var(--color-loader);
    background-color: #516466;
    -webkit-animation-name: to-right;
            animation-name: to-right;
    -webkit-animation-duration: var(--loader-speed);
            animation-duration: var(--loader-speed);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .loader > span:nth-child(even) {
    visibility: hidden;
  }
  .loader > span:first-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
  }
  .loader > span:last-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
    animation-direction: reverse;
  }
  
  @-webkit-keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  
  @keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  @-webkit-keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .connectMobile {
    display: none;
  }
.walletAndMint {
  display: flex;
  flex-flow: row nowrap;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.mintP:hover {
  color: gold;
  cursor: pointer;
}

.btnRed1 {
  margin-right: 40px;
}

.btnRed2 {
  margin-left: 40px;
}

:root {
  --primary: #4f5877;
  --secondary: hsl(224, 20%, 17%);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
.button-49-2,
  .button-49-2:after {
    width: 280px;
    height: 76px;
    line-height: 78px;
    font-size: 20px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: 0.5s ease-in;
  }
  
  .button-49-2:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49-2:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
    opacity: 0;
  }

  .button-49-2:hover span {
    display:none
  }
  
  .button-49-2:hover:before {
    content:"COMING SOON";
  }
  
.button-49,
.button-49:after {
  width: 280px;
  height: 76px;
  line-height: 78px;
  font-size: 20px;
  background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
  border: 0;
  color: #fff;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-49:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);

  content: 'ALTERNATE TEXT';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  -webkit-clip-path: var(--slice-0);
          clip-path: var(--slice-0);
}

.button-49:hover:after {
  -webkit-animation: 1s glitch;
          animation: 1s glitch;
  -webkit-animation-timing-function: steps(2, end);
          animation-timing-function: steps(2, end);
}

@-webkit-keyframes glitch {
  0% {
    -webkit-clip-path: var(--slice-1);
            clip-path: var(--slice-1);
    -webkit-transform: translate(-20px, -10px);
            transform: translate(-20px, -10px);
  }

  10% {
    -webkit-clip-path: var(--slice-3);
            clip-path: var(--slice-3);
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px);
  }

  20% {
    -webkit-clip-path: var(--slice-1);
            clip-path: var(--slice-1);
    -webkit-transform: translate(-10px, 10px);
            transform: translate(-10px, 10px);
  }

  30% {
    -webkit-clip-path: var(--slice-3);
            clip-path: var(--slice-3);
    -webkit-transform: translate(0px, 5px);
            transform: translate(0px, 5px);
  }

  40% {
    -webkit-clip-path: var(--slice-2);
            clip-path: var(--slice-2);
    -webkit-transform: translate(-5px, 0px);
            transform: translate(-5px, 0px);
  }

  50% {
    -webkit-clip-path: var(--slice-3);
            clip-path: var(--slice-3);
    -webkit-transform: translate(5px, 0px);
            transform: translate(5px, 0px);
  }

  60% {
    -webkit-clip-path: var(--slice-4);
            clip-path: var(--slice-4);
    -webkit-transform: translate(5px, 10px);
            transform: translate(5px, 10px);
  }

  70% {
    -webkit-clip-path: var(--slice-2);
            clip-path: var(--slice-2);
    -webkit-transform: translate(-10px, 10px);
            transform: translate(-10px, 10px);
  }

  80% {
    -webkit-clip-path: var(--slice-5);
            clip-path: var(--slice-5);
    -webkit-transform: translate(20px, -10px);
            transform: translate(20px, -10px);
  }

  90% {
    -webkit-clip-path: var(--slice-1);
            clip-path: var(--slice-1);
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }

  100% {
    -webkit-clip-path: var(--slice-1);
            clip-path: var(--slice-1);
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes glitch {
  0% {
    -webkit-clip-path: var(--slice-1);
            clip-path: var(--slice-1);
    -webkit-transform: translate(-20px, -10px);
            transform: translate(-20px, -10px);
  }

  10% {
    -webkit-clip-path: var(--slice-3);
            clip-path: var(--slice-3);
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px);
  }

  20% {
    -webkit-clip-path: var(--slice-1);
            clip-path: var(--slice-1);
    -webkit-transform: translate(-10px, 10px);
            transform: translate(-10px, 10px);
  }

  30% {
    -webkit-clip-path: var(--slice-3);
            clip-path: var(--slice-3);
    -webkit-transform: translate(0px, 5px);
            transform: translate(0px, 5px);
  }

  40% {
    -webkit-clip-path: var(--slice-2);
            clip-path: var(--slice-2);
    -webkit-transform: translate(-5px, 0px);
            transform: translate(-5px, 0px);
  }

  50% {
    -webkit-clip-path: var(--slice-3);
            clip-path: var(--slice-3);
    -webkit-transform: translate(5px, 0px);
            transform: translate(5px, 0px);
  }

  60% {
    -webkit-clip-path: var(--slice-4);
            clip-path: var(--slice-4);
    -webkit-transform: translate(5px, 10px);
            transform: translate(5px, 10px);
  }

  70% {
    -webkit-clip-path: var(--slice-2);
            clip-path: var(--slice-2);
    -webkit-transform: translate(-10px, 10px);
            transform: translate(-10px, 10px);
  }

  80% {
    -webkit-clip-path: var(--slice-5);
            clip-path: var(--slice-5);
    -webkit-transform: translate(20px, -10px);
            transform: translate(20px, -10px);
  }

  90% {
    -webkit-clip-path: var(--slice-1);
            clip-path: var(--slice-1);
    -webkit-transform: translate(-10px, 0px);
            transform: translate(-10px, 0px);
  }

  100% {
    -webkit-clip-path: var(--slice-1);
            clip-path: var(--slice-1);
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@media (min-width: 768px) {

  .button-49,
  .button-49:after {
    width: 250px;
    height: 86px;
    line-height: 88px;
  }
}

.stakePagesBtns {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-top: 15vh;
}

.stakePagesBtns2 {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-top: 50px;
}


.stakeMainPage {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
}

.stakeMainPage2 {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/futuristic-gif-9.73bec76a.gif) no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  overflow: hidden;
}

.csMAin {
  background: rgb(0,28,32);
  background: linear-gradient(100deg, rgba(0,28,32,1) 0%, rgba(0,24,27,1) 69%, rgba(0,28,32,1) 98%);
  background-size: cover;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  overflow: hidden;
}

.aboutPageMain {
  background: rgb(0,30,33);
  background: linear-gradient(355deg, rgba(0,30,33,1) 0%, rgba(0,0,0,1) 69%, rgba(0,42,47,1) 98%);
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  overflow: hidden;
}

.stripe-btn {
  --text-shadow-thickness: 0.05ch;
  --text-shadow-negative-thickness: calc(-1 * var(--text-shadow-thickness));
  position: relative;
  border: 2px solid var(--primary);
  font-size: 3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  color: var(--primary);
  text-transform: uppercase;
  text-shadow:
    var(--text-shadow-thickness) var(--text-shadow-thickness) 0px var(--secondary),
    var(--text-shadow-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary),
    var(--text-shadow-negative-thickness) var(--text-shadow-thickness) 0px var(--secondary),
    var(--text-shadow-negative-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary);
  width: 280px;
  text-align: center;
}

.corners {
  --corner-dimension: 0.7ch;
  --corner-thickness: 3px;
  --corner-negative-thickness: calc(-1 * var(--corner-thickness));
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.corners>i {
  content: "";
  position: absolute;
  width: var(--corner-dimension);
  height: var(--corner-dimension);
}

.corners>i:nth-child(1) {
  top: 0;
  left: 0;
  box-shadow: inset var(--corner-thickness) var(--corner-thickness) 0 var(--primary);
}

.corners>i:nth-child(2) {
  top: 0;
  right: 0;
  box-shadow: inset var(--corner-negative-thickness) var(--corner-thickness) 0 var(--primary);
}

.corners>i:nth-child(3) {
  bottom: 0;
  right: 0;
  box-shadow: inset var(--corner-negative-thickness) var(--corner-negative-thickness) 0 var(--primary);
}

.corners>i:nth-child(4) {
  bottom: 0;
  left: 0;
  box-shadow: inset var(--corner-thickness) var(--corner-negative-thickness) 0 var(--primary);
}

.stripes {
  --stripes-position: 0.3ch;
  content: "";
  position: absolute;
  display: flex;
  justify-content: space-between;
  height: calc(100% - var(--stripes-position) * 2);
  width: calc(100% - var(--stripes-position) * 2);
  top: var(--stripes-position);
  left: var(--stripes-position);
  overflow: hidden;
}

.stripes>i {
  content: "";
  height: calc(100% + 2ch);
  width: 0.5ch;
  background: var(--primary);
  opacity: 0.3;
  -webkit-transform: translateY(-1ch) rotate(37deg);
          transform: translateY(-1ch) rotate(37deg);
  transition: all 0.2s cubic-bezier(0.85, 0, 0.15, 1)
}

.stripes>i:nth-child(1) {
  transition-delay: 0.02s;
}

.stripes>i:nth-child(2) {
  transition-delay: 0.04s;
}

.stripes>i:nth-child(3) {
  transition-delay: 0.06s;
}

.stripes>i:nth-child(4) {
  transition-delay: 0.08s;
}

.stripes>i:nth-child(5) {
  transition-delay: 0.10s;
}

.stripes>i:nth-child(6) {
  transition-delay: 0.12s;
}

.stripes>i:nth-child(7) {
  transition-delay: 0.14s;
}

.stripes>i:nth-child(8) {
  transition-delay: 0.16s;
}

.stripes>i:nth-child(9) {
  transition-delay: 0.18s;
}

.stripes>i:nth-child(10) {
  transition-delay: 0.20s;
}

.stripes>i:nth-child(11) {
  transition-delay: 0.22s;
}

.stripes>i:nth-child(12) {
  transition-delay: 0.24s;
}

.stripes>i:nth-child(13) {
  transition-delay: 0.26s;
}

.stripes>i:nth-child(14) {
  transition-delay: 0.28s;
}

.stripes>i:nth-child(15) {
  transition-delay: 0.3s;
}

.stripes>i:nth-child(16) {
  transition-delay: 0.32s;
}

.stripes>i:nth-child(17) {
  transition-delay: 0.34s;
}

.stripes>i:nth-child(18) {
  transition-delay: 0.36s;
}

.stripes>i:nth-child(19) {
  transition-delay: 0.38s;
}

.stripes>i:nth-child(20) {
  transition-delay: 0.4s;
}

.stripe-btn:hover .stripes>i {
  opacity: 0.5;
}

.futuristic-button {
  /* Other styling for the button */
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #fff;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.2s ease;
  background-color: #e0706c00;
}

.futuristic-button:hover {
  background-color: #ff0;
  box-shadow: 0 0 10px #ff0, 0 0 40px #ff0, 0 0 80px #ff0;
  color: #000;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.announcement {
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
  position: -webkit-sticky;
  margin-top: 40vh;
}


.announcementP1 {
  color: gray;
  font-size: 16px;
}

.announcementP2 {
  color: white;
  font-size: 22px;
}

/* Animation */
.anim-typewriter {
  -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
          animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@-webkit-keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, .75);
  }

  to {
    border-right-color: transparent;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, .75);
  }

  to {
    border-right-color: transparent;
  }
}

.line-2 {
  position: relative;
  top: 50%;
  width: 48%;
  color: rgb(255, 255, 255);
  color: gray;
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid rgba(255, 255, 255, .75);
}

.anim-typewriter2 {
  -webkit-animation: typewriter2 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
          animation: typewriter2 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@-webkit-keyframes typewriter2 {
  from {
    width: 0;
  }

  to {
    width: 130px;
  }
}

@keyframes typewriter2 {
  from {
    width: 0;
  }

  to {
    width: 130px;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, .75);
  }

  to {
    border-right-color: transparent;
  }
}

.line-3 {
  top: 50%;
  color: rgb(0, 194, 219);
  font-size: 18px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid rgba(255, 255, 255, .75);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.tw {
  margin-top: 40px;
}

.anim-typewriter3 {
  -webkit-animation: typewriter3 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
          animation: typewriter3 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@-webkit-keyframes typewriter3 {
  from {
    width: 0;
  }

  to {
    width: 350px;
  }
}

@keyframes typewriter3 {
  from {
    width: 0;
  }

  to {
    width: 350px;
  }
}

.lineP {
  background-color: rgba(99, 99, 99, 0.452);
  width: 100%;
  height: 1px;
}

.futuristic-text {
  /* Other styling for the text */
  position: relative;
  overflow: hidden;
}

.futuristic-text:before {
  content: attr(data-text);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #ff0 0%, #f00 100%);
}

@-webkit-keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.futuristic-text:after {
  content: '|';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #ff0;
  -webkit-animation: typing 1s steps(40, end);
          animation: typing 1s steps(40, end);
}

.kave-btn {
  position: relative;
  border: solid 1px rgba(194, 191, 193, 0.3);
  background: none;
  color: white;
  letter-spacing: 1.8px;
  width: 130px;
  height: 50px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;
  font-size: 16px;
}

.kave-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 60px;
  height: 1px;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  background: #93edfd;
  transition: all 0.25s ease;
}

.kave-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 60px;
  height: 1px;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-btn:hover {
  box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
  color: #00d6fc;
  text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
}

.kave-btn:hover.kave-btn:before {
  left: 0;
  width: 20px;
}

.kave-btn:hover.kave-btn:after {
  right: 0;
  width: 20px;
}

.kave-btn:hover .kave-line:before {
  bottom: 0;
}

.kave-btn:hover .kave-line:after {
  top: 0;
}

.kave-line:before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 1px;
  height: 20px;
  -webkit-transform: translateX(1px);
          transform: translateX(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-line:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 1px;
  height: 20px;
  -webkit-transform: translateX(-1px);
          transform: translateX(-1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.header {
  width: 350px;
  height: 162px;
}

.mintP {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 30px;
  color: white;
  font-size: 27px;
}

.pic {
  /* 
    width: 25%; 
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    margin-top: -30%;
    bottom: 0;
    animation: Slide_Left_Right 16.25s ease infinite, Opacity 16.25s ease infinite;*/
  -webkit-transform: rotate(-27deg);
          transform: rotate(-27deg);
  width: 40%;
  position: sticky;
  position: -webkit-sticky;
  margin-top: -30%;
  bottom: 0;
  margin-left: 85%;
  margin-top: -80%;
}

@-webkit-keyframes Slide_Left_Right {

  0%,
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  12.5%,
  75% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

}

@keyframes Slide_Left_Right {

  0%,
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  12.5%,
  75% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

}

@-webkit-keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

.back {
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
  z-index: 100000000;
}

.backDiv {
  z-index: 1000000000000000000;
}

.blur {
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #00000094;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;

}

.v2Quote {
  text-align: center;
  color: white;

  border: 2px #facc15 solid;
  position: fixed;
  padding: 15px;
  font-size: 30px;
  top: 50%;
  left: 50%;
  z-index: 100000;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

}

.stakeMainHome {
  display: flex;
  flex-flow: column nowrap;
  background-color: black;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  background: rgb(12, 17, 40);
  background: linear-gradient(99deg, rgba(12, 17, 40, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(10, 33, 23, 1) 94%);
}

.homeBtn {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: space-between;
  width: 30%;
}

.homeBtn button {
  color: black;
  background-color: #facc15;
  width: 200px;
  padding: 20px;

}

.homeBtn button:hover {
  margin-bottom: 2%;
}

.homeImages {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 2%;
}

.homeImages1 {
  -webkit-animation: Slide_Down1 6s ease infinite;
          animation: Slide_Down1 6s ease infinite;
}

.homeImages2 {
  -webkit-animation: Slide_Down2 5s ease infinite;
          animation: Slide_Down2 5s ease infinite;
}

@-webkit-keyframes Slide_Down1 {
  0% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

  100% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }

}

@keyframes Slide_Down1 {
  0% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

  100% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }

}

@-webkit-keyframes Slide_Down2 {
  0% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

  100% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }

}

@keyframes Slide_Down2 {
  0% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

  100% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }

}


.right {
  width: 20%;
  margin-top: -70%;
}

.homeLogo {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 2%;

}

.submitBtn {
  background-color: #44a06c;
  color: white;
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
}

.buyDetails2 {
  border: 3px black solid;
  width: 50%;
  padding: 10px;
}

.emailSectionH {
  color: black;

  font-size: 20px;
  margin-bottom: 10px;
  color: #facc15;
}

.emailSection {
  color: #facc15;
  background: rgba(255, 255, 255, 0.096);
  border: 2px dashed #facc15;
  margin-bottom: 5%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.emailSection2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding: 20px;
  flex-flow: column;
}

.prizeBtn {
  background-color: #facc15;
  color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  width: 150px;
  box-shadow: 0px 2px #a58503;
}

.prizeBtnDis {
  background-color: #8f8767;
  color: rgb(61, 61, 61);
  border: none;
  width: 150px;
  box-shadow: 0px 2px #978e6b;
  cursor: no-drop;
}

.step {
  color: white;
  font-size: 15px;
}

.connect2Mob {
  display: none;
}

.tableMobile {
  display: none;
}

.number {
  border-radius: 10px;
  border: 2px rgb(59, 131, 240) solid;
  padding: 10px;
  width: 300px;
  text-align: center;
}

.formBtn {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.adminBtns button {
  width: 300px;
  padding: 15px;
  font-size: 15px;
  cursor: pointer;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  font-weight: 700;
  color: #ffffff;
  background-color: #115dce00;
  border-radius: 10px;
  border: 2px rgb(59, 131, 240) solid;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
}

.sentOrders {
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  background-color: #3b3b3b00;
  width: 60%;
  padding: 10px;

}

.view2 {
  margin-left: 5%;
  width: 20%;
}

.or {
  color: white;
  font-weight: 500;
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.view {
  margin-right: 5%;
}

.adminBtnsMain {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-top: 5%;
  border-radius: 5px;
  padding: 20px;
  width: 50%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid #d79f60;
}

.orders {
  margin-top: 1%;
}



.t1 {
  color: white;
  font-size: 20px;
  text-align: center;
  margin-top: 5%;
  text-decoration: underline;
  font-weight: 500;
}

.t2 {
  color: white;
  font-size: 20px;
  text-align: center;
  margin-top: 5%;
  text-decoration: underline;
  font-weight: 500;
}

table {
  color: white;
  border-collapse: collapse;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 5%;
}

td,
th {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;

}

.hideTable {
  display: none;
}

.adminBtns {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 70%;
  margin-left: auto;
  margin-right: auto;

}



.adminBtns button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}




.buyDetails {
  color: white;
  font-size: 25px;
  text-align: left;
  margin-left: 3%;
  margin-top: 5%;
}

.btnsMainMob {
  display: none;
}

.stakeWC {
  background-color: gold;
  border-style: none;
  color: black;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 100px;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.stakeWCMain {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.home {
  width: 50px;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  cursor: pointer;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}

.home:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.stakeWC:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.navBarStake {
  justify-content: center;
  margin-bottom: 2%;

}

.navBarStake2 {
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  width: 97%;
  display: flex;

}

.triangleTopRight {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 200px 200px 0;
  border-color: transparent #f3ba2f transparent transparent;
  position: sticky;
  position: -webkit-sticky;
  bottom: 80%;
  left: 90%;
  margin-top: -70%;
}

.v1 {
  width: 50px;
  height: 50px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 90%;
  left: 120%;
  margin-top: -70%;
  display: inline-block;
  z-index: 100000;
}


.btns {
  z-index: 10000;
  width: 200px;
  background-color: #586285;
  color: white;
  padding: 5px;
  font-weight: 500;
  margin-bottom: 20px;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  transition: ease-in 0.5s;
}

.btnsDis {
  width: 150px;
  background-color: #77946c;
  border: none;
  color: rgb(255, 255, 255);
  padding: 5px;
  font-weight: 500;
  margin-bottom: 5%;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  cursor: no-drop;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btns2 {
  width: 200px;
  color: white;
  padding: 5px;
  font-weight: 500;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  background-color: #586285;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border: none;
  transition: ease-in 0.5s;

}

.btns2Dis {
  width: 150px;
  background-color: rgb(196, 134, 134);
  cursor: no-drop;
  color: rgb(255, 255, 255);
  padding: 5px;
  font-weight: 500;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btns:hover {
  background-color: #29cdfe;
  color: blue;
}

.btns2:hover {
  background-color: #29cdfe;
  color: blue;
}

.btnsMain {
  display: flex;
  flex-flow: column nowrap;
  margin-top: auto;
  margin-bottom: auto;
  display: none;
}

.btnsMainBig {
  display: flex;
  flex-flow: column nowrap;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.markBar {
   margin-top: 2%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto; 
  padding: 15px;
  
}

.markBarCon {
  background: rgba(172, 172, 172, 0.158);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  flex-flow: row nowrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding: 5px;
  border: 3px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #60e1f8, #006c80);
  margin-top: 2%;
  margin-bottom: 2%;
  background: url(/static/media/Container.64f3b4d9.png) no-repeat center center fixed;
  background-size: cover;
}

.markBar2 {
  flex-flow: column nowrap;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: -10%;
}

.num {
  font-size: 17px;
  color: #bdf1f5;
  text-align: center;
}

.name {
  color: #bdf1f5;
  font-size: 15px;
  text-align: center;

}

.num2 {
  font-size: 20px;
  color: white;

  text-align: center;
}

.name2 {
  color: white;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}

.markBar img {
  width: 70px;
  height: 70px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 20%;
}

.markBarMain {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.stakeMain {
  display: flex;
  flex-flow: column nowrap;
  background-color: black;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/IuNL.e000bf3c.gif) no-repeat center center fixed;
  background-size: cover;
}

.stakeMain2 {
  border-right: 20px #f3ba2f solid;
  border-top: #f3ba2f 20px solid;

}

.sticky {
  width: 20%;

  bottom: 0;
  right: 90%;
  margin-top: -10%;
}

.items {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.board {
  margin-top: 5%;
  margin-bottom: 100px;
  padding: 35px;
  border: #ffffff 4px solid;
  box-shadow: -10px 10px rgba(75, 75, 75, 0.842);

  margin-left: 1%;
  margin-right: 1%;

}

.boardMain {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.nameStake {
  width: 50%;
}

.carrots {
  width: 90px;
  margin-top: auto;
  margin-bottom: auto;
}

.egg {
  width: 70px;
  height: 70px;
  margin-top: auto;
  margin-bottom: auto;
}

.egg2 {
  width: 95px;
  height: 95px;
  margin-top: auto;
  margin-bottom: auto;
}

.boardImg {
  background-color: #ffb963;

}

.boardPics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  margin-bottom: 8%;
}

.approveBtns {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  margin-top: 2%;
}

.approveBtns1 {
  border: 4px solid;
  background-color: rgba(206, 8, 8, 0);
  color: rgb(255, 255, 255);
  padding: 15px;
  font-size: 18px;

  width: 400px;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}

.approveBtns1:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.approveBtns2:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.approveBtns3:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.approveBtns3 {
  border: 4px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
  background-color: rgba(206, 8, 8, 0);
  box-shadow: 0 0 5px red;
  color: white;
  color: white;
  padding: 20px;
  font-size: 23px;

  width: 400px;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;

}

.approveBtns3Dis {
  background-color: rgb(224, 111, 111);
  border: none;
  cursor: no-drop;
  color: rgb(187, 183, 183);

  padding: 20px;
  font-size: 23px;

  width: 400px;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.approveBtns2 {
  background: none;
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-size: 23px;
  border: 4px #70512b solid;

  width: 200px;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}

.rabbitImg {
  width: 110px;
  margin-top: 20%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 2%;
}

.rabbitImgLogo {
  width: 110px;
  margin-top: 20%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  border-radius: 100px;
  margin-bottom: 2%;

}

.rabbitImg2 {
  width: 110px;
  margin-top: 20%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.itemsDiv {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 5%;
  width: 40%;
}

.itemImg {
  width: 400px;
  height: 213px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.itemImg2 {
  width: 400px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.itemImg3 {
  width: 400px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.itemImg4 {
  width: 400px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.itemImg5 {
  width: 400px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.itemName {
  color: white;
  font-weight: 500;
  text-align: center;
  padding-top: 2%;
  font-size: 20px;
  background-color: #000000;
}

.buyBtn {
  background-color: #000000;
  padding-top: 2%;
  padding-bottom: 3%;

}

.buy {
  background-color: #d79f60;
  color: rgb(0, 0, 0);
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 50px;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  border: white 2px solid;
  font-weight: 700;
  transition: ease 0.5s;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 5%;
}

.buy:hover {
  background-color: rgb(0, 0, 0);
  color: #d79f60;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

.logo2 {
  width: 20%;
}

.discordF {
  padding-left: 10px;
  padding-right: 10px;
}

.socialAndLogo {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
}

/*.............. FAQ ..............*/


summary {
  font-size: 20px;
  font-weight: 600;
  background-color: #ffffff;
  color: #487a17;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  border-radius: 10px;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  -webkit-animation: sweep .5s ease-in-out;
          animation: sweep .5s ease-in-out;
}

@-webkit-keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 50%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;

  color: white;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding: 2px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 10%;
}


.rmPic img {
  width: 100%;
  background-size: cover;
}

.rmMain {
  margin-top: 10%;
}

.unstakeImgM {
  display: none;
}

.formStake {
  z-index: 1;
}

.allWrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
}

.allWrap2 {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
}

.light {
  background-size: cover;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  overflow: hidden;
}

.mintMain {
  background: linear-gradient(100deg, rgba(11,18,32,1) 0%, rgba(0,7,20,0.7314523221397935) 69%, rgba(11,18,32,1) 98%), url(/static/media/grid_0.a399d903.png) no-repeat center center fixed;
  background-size: cover;
  background-size: cover;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  overflow: hidden;
}

.light2 {
  width: 100%;
  background-color: #000000;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  overflow: hidden;
}


.intro {
  color: white;
  font-size: 100px;
  text-align: center;
  z-index: 1;
  font-family: "Third Rail - Demo";
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ff9900;
}

.in2 {
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  z-index: 1;
  justify-content: center;
}

.parrot {
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  box-shadow: #32743b7a -15px 15px;
  border-radius: 20px;
}


@-webkit-keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}


@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.intro2 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  padding-bottom: 10px;
  text-align: center;
  z-index: 1;
  font-weight: 500;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 470px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.055);
  border-radius: 10px;
  margin-bottom: 70px;
  box-shadow: 0 0 20px #00d6fc;
  margin-top: -80px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
    }

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 15px;
  color: #e0706c;
  text-align: center;

}

.successfully {
  margin-top: 1%;
  font-size: 20px;
  color: #000000;
  text-align: center;
}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: rgb(204, 204, 204);
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

}

.discord {
  padding-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.discord:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

::-webkit-scrollbar-thumb {
  background: #67c4b6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8fc7bf;
}


.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 5px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.connect2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 3%;
}

.connect div {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

#fontSize {
  font-size: 16px;
  color: white;
  z-index: 1;

}

#fontSize:hover {
  color: #00E6F6;
  cursor: pointer;
  transition: ease 0.5s;
}

#fontSizeActive {
  font-size: 16px;
  color: #00E6F6;
 }

#fontSizeActive:hover {
  color: white;
  cursor: pointer;
  transition: ease 0.5s;
}



@-webkit-keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}



@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.mintbuttondiv {
  margin-top: 20px;
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  width: 50px;
  border: none;
  cursor: pointer;
  background-color: #4bbdff;
}

.btnfos-0-2-2 {
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  width: 50px;
  border: none;
  background-color: #4bbdff;

  cursor: pointer;

}

.btnfos-0-2-2:hover{
  border: 2px solid #00a2ff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
}

.btnfos-0-2:hover{
  border: 2px solid #00a2ff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
}

.btnfos-0-3 {
  margin-top: 20px;
  font-size: 20px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 10px;
  border: none;
  width: 200px;
  border-top-left-radius: 10px;
 }

/* CSS */
.button-92 {
  --c: #fff;
  /* text color */
  background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
    #004dff;
  background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
    #004dff;
  /* background color */
  color: #0000;
  border: none;
  -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
          transform: perspective(500px) rotateY(calc(20deg * -1));
  -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
          transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
  text-shadow: calc(-1 * 0.08em) -.01em 0   var(--c),
    calc(-1 * -0.08em)  .01em 2px #0004;
  text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
    calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
  outline-offset: .1em;
  transition: 0.3s;
}

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  cursor: pointer;
  padding: .1em .3em;
}

.btnfos-0-3:hover {

  color: rgb(78, 78, 78);
  cursor: pointer;

}

.btnfos-0-3:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
}

.nftamount {
  color: #44a06c;
  font-size: 70px;
}

.walletConnect {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  z-index: 1;

}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: #00FF00
}


.headers {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 15px;
  margin-left: auto;
  margin-right: auto;

  /* border-left: #2d363600;
  border-right: #2d363600;*/
}

.headers2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

}

.introduction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  margin-top: -120px;

}

.wallet2 {
  background-color: #d79f6000;
  color: rgb(0, 0, 0);
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 50px;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  border: white 2px solid;
  font-weight: 700;
  transition: ease 0.5s;

}

.wallet2:hover {
  background-color: rgb(0, 0, 0);
  color: #d79f60;
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
          animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}


.wallet3 {
  z-index: 10000;
  background-color: black;
  border: solid 3px;
  color: rgb(255, 255, 255);
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  z-index: 1;
  margin-bottom: 10%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;

}

.lookDown {
  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}


.wallet3:hover {
  -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
          animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.wallet3::after {
  transition: all 0.8s
}

.totalSupply {
  text-align: center;
  font-size: 40px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: #00E6F6;
  padding-bottom: 5%;
}

@-webkit-keyframes Slide_Up {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

@-webkit-keyframes Slide_Up_Tag {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

@-webkit-keyframes Slide_Up_Buttons {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

.footer {
  position: static;
  margin-bottom: 5%;
}

.copyright {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}


.copyright2 {
  color: rgba(255, 255, 255, 0.712);
  text-align: center;
  font-size: 12px;
  padding-top: 6px;
}

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

.images_show {
  width: 90%;
}

.hide {
  display: none;
}

.stake {
  display: block;
  background-color: red;
  color: white;
  border-radius: 10px;
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.stake2 {
  display: block;
  background-color: red;
  color: white;
  border-radius: 10px;
  border: none;
  width: 85px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}

.stake3 {
  display: block;
  background-color: red;
  color: white;
  border-radius: 10px;
  border: none;
  width: 85px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: center;
}

.tokenIds {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 10%;
}

.tokenIds2 {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20%;
}

.tS {
  color: #3a9ae8;
  text-align: center;
  margin-top: 2%;
  font-size: 40px;
  font-family: 'Comic Kings', sans-serif;
}

.unstakeImg {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;

}

.imagesAndBtn {
  justify-content: center;
}

.flip-card {
  background-color: transparent;
  width: 150px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  background-color: #e0706c;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  margin-top: 10%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btnCenter {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.flip-card-front {
  /* background-color: #bbb;*/
  color: black;
}

.flip-card-back {
  /*background-color: #2980b9;*/
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.loginFormNote {
  height: 90%;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}

.formMain {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 80%;
  height: 80vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border-radius: 10px;
  z-index: 100000;
  border: white 3px solid;

}

.formMain2 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 50%;
  height: 80vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border-radius: 10px;
  z-index: 100000;
  border: white 3px solid;
  padding: 10px;
}

.formMain1 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.formMain1-1 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;
  background-color: #00000062;
}

.ownNFT {
  margin-bottom: 80px;
  margin-top: 80px;
}

.elementName {
  font-weight: 500;
  color: red;
  font-size: 20px;
  text-align: center;
}

.elementName2 {
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.elementName3 {
  font-weight: 500;
  color: red;
  font-size: 20px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.medalDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;

  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.rewards {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

}

.discordB {
  display: none;
}

.medal {
  width: 35px;
  height: 35px;
}

@-webkit-keyframes shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@-webkit-keyframes Slide_Left {
  0% {
    -webkit-transform: translateX(250px);
            transform: translateX(250px);
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes Slide_Left {
  0% {
    -webkit-transform: translateX(250px);
            transform: translateX(250px);
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  12.5%,
  75% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@-webkit-keyframes scaleIn {
  from {
    -webkit-transform: scale(.5, .5);
            transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    -webkit-transform: scale(2.5, 2.5);
            transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes scaleIn {
  from {
    -webkit-transform: scale(.5, .5);
            transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    -webkit-transform: scale(2.5, 2.5);
            transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@-webkit-keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

.stakebtn {
  padding: 15px;
  font-size: 15px;
  cursor: pointer;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  margin-left: 3%;
  margin-right: 3%;
  font-weight: 700;
  color: #ffffff;
  background-color: #ce5d11;
  border-radius: 50px;
  border: none;
  box-shadow: #b15411 0px 5px;
}

.stakebtn2 {
  padding: 15px;
  font-size: 15px;
  cursor: pointer;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  margin-left: 3%;
  margin-right: 3%;
  font-weight: 700;
  color: #ffffff;
  background-color: #ce5d11;
  border-radius: 50px;
  border: none;
  box-shadow: #b15411 0px 5px;
}

.stakebtn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.stakebtn2:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.stakebtnDiv {
  z-index: 10000;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.logoPic {
  width: 110px;
  border-radius: 100%;
  border: 2px solid #ffffff;
}

.logoPic2 {
  border-radius: 50%;
  width: 10%;
  border: 1px solid #39a7f1;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
}

.logo {
  justify-content: flex-start;
  margin-top: auto;
  margin-bottom: auto;
}

.osPic {
  width: 30px;
  height: 30px;
  cursor: pointer;

}

.btnT {
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 2%;
  margin-top: 5%;
}

* {
  box-sizing: border-box;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 4rem;
  background-color: #e89d38;
  padding-left: 100%;
  margin-top: 5%;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 4rem;
  line-height: 4rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 50s;
}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 18px;
  color: #ffffff;
  font-weight: 800;
}

.boxWrapC {
  margin-top: 10%;
}

.mainTB {
  text-align: center;
  color: white;

  font-size: 50px;

}

.mainTBP {
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 20px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.sections3 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 5%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-color: #64a81f;
  border-radius: 50px;
  border: 2px white solid;
  padding: 10px;
}

.sT {
  text-align: center;
  color: white;

  font-size: 25px;
  margin-bottom: 1%;
}

.sT2 {
  text-align: center;
  color: white;
  font-weight: 500;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.footer2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10%;
}

.footer2 img {
  margin-left: 10px;
  margin-right: 10px;
  width: 40px;
  cursor: pointer;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}

.footer2 img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.tName {
  color: white;
  justify-content: flex-start;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  font-size: 50px;

  cursor: pointer;
}

.stakeT {
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  font-size: 45px;

  letter-spacing: 2px;
}

.winnerSection {
  margin-top: 5%;
  margin-bottom: 5%;
}

.winnerT {
  text-align: center;
  color: #facc15;

  font-size: 50px;
  -webkit-text-decoration: underline white;
          text-decoration: underline white;
}

table {
  border-collapse: collapse;
  width: 80%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #1f1f1f;
  color: #facc15;
}

.bg {
  background-color: #facc15;
  color: black;
}



.imageSet {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.imageSet img {
  width: 30%;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.imageSet img:hover {
  width: 30%;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.logoNFT {
  width: 120px;
  height: 120px;
  z-index: 1;

}

.glitch {
  color: white;
  font-size: 100px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}

@-webkit-keyframes noise-anim {
  0% {
    clip: rect(66px, 9999px, 49px, 0);
  }
  5% {
    clip: rect(4px, 9999px, 36px, 0);
  }
  10% {
    clip: rect(31px, 9999px, 58px, 0);
  }
  15% {
    clip: rect(94px, 9999px, 53px, 0);
  }
  20% {
    clip: rect(94px, 9999px, 12px, 0);
  }
  25% {
    clip: rect(74px, 9999px, 91px, 0);
  }
  30% {
    clip: rect(75px, 9999px, 29px, 0);
  }
  35% {
    clip: rect(91px, 9999px, 21px, 0);
  }
  40% {
    clip: rect(47px, 9999px, 88px, 0);
  }
  45% {
    clip: rect(42px, 9999px, 47px, 0);
  }
  50% {
    clip: rect(87px, 9999px, 98px, 0);
  }
  55% {
    clip: rect(22px, 9999px, 20px, 0);
  }
  60% {
    clip: rect(3px, 9999px, 76px, 0);
  }
  65% {
    clip: rect(11px, 9999px, 92px, 0);
  }
  70% {
    clip: rect(80px, 9999px, 17px, 0);
  }
  75% {
    clip: rect(61px, 9999px, 57px, 0);
  }
  80% {
    clip: rect(2px, 9999px, 48px, 0);
  }
  85% {
    clip: rect(18px, 9999px, 46px, 0);
  }
  90% {
    clip: rect(18px, 9999px, 50px, 0);
  }
  95% {
    clip: rect(78px, 9999px, 35px, 0);
  }
  100% {
    clip: rect(63px, 9999px, 60px, 0);
  }
}

@keyframes noise-anim {
  0% {
    clip: rect(66px, 9999px, 49px, 0);
  }
  5% {
    clip: rect(4px, 9999px, 36px, 0);
  }
  10% {
    clip: rect(31px, 9999px, 58px, 0);
  }
  15% {
    clip: rect(94px, 9999px, 53px, 0);
  }
  20% {
    clip: rect(94px, 9999px, 12px, 0);
  }
  25% {
    clip: rect(74px, 9999px, 91px, 0);
  }
  30% {
    clip: rect(75px, 9999px, 29px, 0);
  }
  35% {
    clip: rect(91px, 9999px, 21px, 0);
  }
  40% {
    clip: rect(47px, 9999px, 88px, 0);
  }
  45% {
    clip: rect(42px, 9999px, 47px, 0);
  }
  50% {
    clip: rect(87px, 9999px, 98px, 0);
  }
  55% {
    clip: rect(22px, 9999px, 20px, 0);
  }
  60% {
    clip: rect(3px, 9999px, 76px, 0);
  }
  65% {
    clip: rect(11px, 9999px, 92px, 0);
  }
  70% {
    clip: rect(80px, 9999px, 17px, 0);
  }
  75% {
    clip: rect(61px, 9999px, 57px, 0);
  }
  80% {
    clip: rect(2px, 9999px, 48px, 0);
  }
  85% {
    clip: rect(18px, 9999px, 46px, 0);
  }
  90% {
    clip: rect(18px, 9999px, 50px, 0);
  }
  95% {
    clip: rect(78px, 9999px, 35px, 0);
  }
  100% {
    clip: rect(63px, 9999px, 60px, 0);
  }
}
.glitch:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: white;
  background: black;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
  text-align: center;

}

@-webkit-keyframes noise-anim-2 {
  0% {
    clip: rect(60px, 9999px, 88px, 0);
  }
  5% {
    clip: rect(100px, 9999px, 75px, 0);
  }
  10% {
    clip: rect(90px, 9999px, 68px, 0);
  }
  15% {
    clip: rect(3px, 9999px, 80px, 0);
  }
  20% {
    clip: rect(49px, 9999px, 92px, 0);
  }
  25% {
    clip: rect(67px, 9999px, 73px, 0);
  }
  30% {
    clip: rect(7px, 9999px, 24px, 0);
  }
  35% {
    clip: rect(4px, 9999px, 13px, 0);
  }
  40% {
    clip: rect(32px, 9999px, 46px, 0);
  }
  45% {
    clip: rect(38px, 9999px, 49px, 0);
  }
  50% {
    clip: rect(23px, 9999px, 90px, 0);
  }
  55% {
    clip: rect(31px, 9999px, 99px, 0);
  }
  60% {
    clip: rect(9px, 9999px, 92px, 0);
  }
  65% {
    clip: rect(11px, 9999px, 53px, 0);
  }
  70% {
    clip: rect(97px, 9999px, 61px, 0);
  }
  75% {
    clip: rect(69px, 9999px, 1px, 0);
  }
  80% {
    clip: rect(39px, 9999px, 42px, 0);
  }
  85% {
    clip: rect(88px, 9999px, 57px, 0);
  }
  90% {
    clip: rect(59px, 9999px, 58px, 0);
  }
  95% {
    clip: rect(90px, 9999px, 49px, 0);
  }
  100% {
    clip: rect(75px, 9999px, 15px, 0);
  }
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(60px, 9999px, 88px, 0);
  }
  5% {
    clip: rect(100px, 9999px, 75px, 0);
  }
  10% {
    clip: rect(90px, 9999px, 68px, 0);
  }
  15% {
    clip: rect(3px, 9999px, 80px, 0);
  }
  20% {
    clip: rect(49px, 9999px, 92px, 0);
  }
  25% {
    clip: rect(67px, 9999px, 73px, 0);
  }
  30% {
    clip: rect(7px, 9999px, 24px, 0);
  }
  35% {
    clip: rect(4px, 9999px, 13px, 0);
  }
  40% {
    clip: rect(32px, 9999px, 46px, 0);
  }
  45% {
    clip: rect(38px, 9999px, 49px, 0);
  }
  50% {
    clip: rect(23px, 9999px, 90px, 0);
  }
  55% {
    clip: rect(31px, 9999px, 99px, 0);
  }
  60% {
    clip: rect(9px, 9999px, 92px, 0);
  }
  65% {
    clip: rect(11px, 9999px, 53px, 0);
  }
  70% {
    clip: rect(97px, 9999px, 61px, 0);
  }
  75% {
    clip: rect(69px, 9999px, 1px, 0);
  }
  80% {
    clip: rect(39px, 9999px, 42px, 0);
  }
  85% {
    clip: rect(88px, 9999px, 57px, 0);
  }
  90% {
    clip: rect(59px, 9999px, 58px, 0);
  }
  95% {
    clip: rect(90px, 9999px, 49px, 0);
  }
  100% {
    clip: rect(75px, 9999px, 15px, 0);
  }
}
.glitch:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: white;
  background: black;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
  text-align: center;

}

.aboutPageMain2{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
   margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.aboutP{
  color: rgb(197, 247, 247);
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  text-align: justify;
}

.aboutDiv{
  color: rgb(197, 247, 247);
  width: 80%;
  color: #00d6fc;
  margin-bottom: 60px;
  text-align: justify;
  justify-content: flex-start;
}

.cMain{
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.cMain img{
  width: 70px;
  height: 70px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.cMain div{
  color: rgb(167, 167, 167);
  text-align: center;
  margin-top: -12px;
  margin-bottom: 30px;
}

.cLine{
  background-color: rgba(99, 99, 99, 0.452);
  width: 100%;
  height: 1px;
}

.beast{
  width: 330px;
  height: 330px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
 }

.price{
  text-align: center;
  color: rgb(0, 250, 167);
}

.nftminter2{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  color: #b8eff8;
  font-size: 40px;
}

.nftminter2Div{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.center{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.placeAndWL{
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 130px;
  margin-top: -50px;}

/* CSS */
.button-89 {
  --b: 3px;   /* border thickness */
  --s: .45em; /* size of the corner */
  --color: #ffffff;
  
  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;

  border: 0;

  -ms-user-select: none;

      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-89:hover,
.button-89:focus-visible{
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}

.button-89:active {
  background: var(--color);
  color: #fff;
}

input{
  border: white 2px solid;
  font-size: 16px;
  padding: 6px;
  margin-right: 20px;
  background-color: rgba(0, 0, 0, 0.432);
  color: #00e1ff;
}


.px-app,
.px-app *,
.px-app *::before,
.px-app *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}
.px-glitch {
  text-align: center;
  color: white;
}

.px-glitchtext {
  font-size: 5rem;
  font-weight: 600;
  text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
  display: block;
}
.px-glitchtext-anim {
  -webkit-animation: glitchtext 300ms linear infinite;
          animation: glitchtext 300ms linear infinite;
}
@-webkit-keyframes glitchtext {
  0% {
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
  }
  20% {
    text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
  }
  40% {
    text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
      2px 1px 0 rgba(0, 255, 255, 1);
  }
  60% {
    text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
  }
  80% {
    text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
  }
  100% {
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
  }
}
@keyframes glitchtext {
  0% {
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
  }
  20% {
    text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
  }
  40% {
    text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
      2px 1px 0 rgba(0, 255, 255, 1);
  }
  60% {
    text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
  }
  80% {
    text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
  }
  100% {
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
  }
}

.px-glitchsvg {
  font-size: 4rem;
  -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
    drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
          filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
    drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
}
.px-glitchsvg-anim {
  -webkit-animation: glitchsvg 200ms none infinite;
          animation: glitchsvg 200ms none infinite;
}
@-webkit-keyframes glitchsvg {
  0% {
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
  20% {
    -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
  }
  40% {
    -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
      drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
      drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
  }
  60% {
    -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
      drop-shadow(0 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
      drop-shadow(0 0 0 rgba(0, 255, 255, 1));
  }
  80% {
    -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
      drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
      drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
  }
  100% {
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
}
@keyframes glitchsvg {
  0% {
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
  20% {
    -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
  }
  40% {
    -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
      drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
      drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
  }
  60% {
    -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
      drop-shadow(0 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
      drop-shadow(0 0 0 rgba(0, 255, 255, 1));
  }
  80% {
    -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
      drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
      drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
  }
  100% {
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
}

.csPage{
  margin-top: 20vh;
}

:root {
  --color-bg: #eaeaea;
  --color-loader: #222;
  --loader-size: 100px;
  --loader-speed: 500ms;
}

.loadMain{
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.loader {
  --loader-height: calc(var(--loader-size) / 7);
  display: flex;
  position: relative;
}
.loader::before {
  content: '';
  position: absolute;
}
.loader::before,
.loader > span {
  display: block;
  width: var(--loader-height);
  height: var(--loader-height);
  border-radius: 50%;
  background: var(--color-loader);
  background-color: #516466;
  -webkit-animation-name: to-right;
          animation-name: to-right;
  -webkit-animation-duration: var(--loader-speed);
          animation-duration: var(--loader-speed);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.loader > span:nth-child(even) {
  visibility: hidden;
}
.loader > span:first-child {
  -webkit-animation-name: scale-up;
          animation-name: scale-up;
}
.loader > span:last-child {
  -webkit-animation-name: scale-up;
          animation-name: scale-up;
  animation-direction: reverse;
}

@-webkit-keyframes to-right {
  to {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
}

@keyframes to-right {
  to {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
}
@-webkit-keyframes scale-up {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
    .connectMobile {
    display: none;
  }
  .walletAndMint {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .mintP:hover {
    color: gold;
    cursor: pointer;
  }
  
  .btnRed1 {
    margin-right: 40px;
  }
  
  .btnRed2 {
    margin-left: 40px;
  }
  
  :root {
    --primary: #4f5877;
    --secondary: hsl(224, 20%, 17%);
  }

  .button-49-2,
  .button-49-2:after {
    width: 280px;
    height: 76px;
    line-height: 78px;
    font-size: 20px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: 0.5s ease-in;
  }
  
  .button-49-2:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49-2:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
    opacity: 0;
  }

  .button-49-2:hover span {
    display:none
  }
  
  .button-49-2:hover:before {
    content:"COMING SOON";
  }
  
  @-webkit-keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49-2,
    .button-49-2:after {
      width: 250px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
  
  .button-49,
  .button-49:after {
    width: 280px;
    height: 76px;
    line-height: 78px;
    font-size: 20px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
   
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
  }
  
  @keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 250px;
      height: 86px;
      line-height: 88px;
    }
  }
  
  .stakePagesBtns {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 15vh;
  }

  
  .stakePagesBtns2 {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 50px;
  }
  
  .stakeMainPage {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .stakeMainPage2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/futuristic-gif-9.73bec76a.gif) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .csMAin {
    background: rgb(0,28,32);
    background: linear-gradient(100deg, rgba(0,28,32,1) 0%, rgba(0,24,27,1) 69%, rgba(0,28,32,1) 98%);
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .aboutPageMain {
    background: rgb(0,30,33);
    background: linear-gradient(355deg, rgba(0,30,33,1) 0%, rgba(0,0,0,1) 69%, rgba(0,42,47,1) 98%);
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .stripe-btn {
    --text-shadow-thickness: 0.05ch;
    --text-shadow-negative-thickness: calc(-1 * var(--text-shadow-thickness));
    position: relative;
    border: 2px solid var(--primary);
    font-size: 3rem;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    color: var(--primary);
    text-transform: uppercase;
    text-shadow:
      var(--text-shadow-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary);
    width: 280px;
    text-align: center;
  }
  
  .corners {
    --corner-dimension: 0.7ch;
    --corner-thickness: 3px;
    --corner-negative-thickness: calc(-1 * var(--corner-thickness));
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .corners>i {
    content: "";
    position: absolute;
    width: var(--corner-dimension);
    height: var(--corner-dimension);
  }
  
  .corners>i:nth-child(1) {
    top: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(2) {
    top: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(3) {
    bottom: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(4) {
    bottom: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .stripes {
    --stripes-position: 0.3ch;
    content: "";
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: calc(100% - var(--stripes-position) * 2);
    width: calc(100% - var(--stripes-position) * 2);
    top: var(--stripes-position);
    left: var(--stripes-position);
    overflow: hidden;
  }
  
  .stripes>i {
    content: "";
    height: calc(100% + 2ch);
    width: 0.5ch;
    background: var(--primary);
    opacity: 0.3;
    -webkit-transform: translateY(-1ch) rotate(37deg);
            transform: translateY(-1ch) rotate(37deg);
    transition: all 0.2s cubic-bezier(0.85, 0, 0.15, 1)
  }
  
  .stripes>i:nth-child(1) {
    transition-delay: 0.02s;
  }
  
  .stripes>i:nth-child(2) {
    transition-delay: 0.04s;
  }
  
  .stripes>i:nth-child(3) {
    transition-delay: 0.06s;
  }
  
  .stripes>i:nth-child(4) {
    transition-delay: 0.08s;
  }
  
  .stripes>i:nth-child(5) {
    transition-delay: 0.10s;
  }
  
  .stripes>i:nth-child(6) {
    transition-delay: 0.12s;
  }
  
  .stripes>i:nth-child(7) {
    transition-delay: 0.14s;
  }
  
  .stripes>i:nth-child(8) {
    transition-delay: 0.16s;
  }
  
  .stripes>i:nth-child(9) {
    transition-delay: 0.18s;
  }
  
  .stripes>i:nth-child(10) {
    transition-delay: 0.20s;
  }
  
  .stripes>i:nth-child(11) {
    transition-delay: 0.22s;
  }
  
  .stripes>i:nth-child(12) {
    transition-delay: 0.24s;
  }
  
  .stripes>i:nth-child(13) {
    transition-delay: 0.26s;
  }
  
  .stripes>i:nth-child(14) {
    transition-delay: 0.28s;
  }
  
  .stripes>i:nth-child(15) {
    transition-delay: 0.3s;
  }
  
  .stripes>i:nth-child(16) {
    transition-delay: 0.32s;
  }
  
  .stripes>i:nth-child(17) {
    transition-delay: 0.34s;
  }
  
  .stripes>i:nth-child(18) {
    transition-delay: 0.36s;
  }
  
  .stripes>i:nth-child(19) {
    transition-delay: 0.38s;
  }
  
  .stripes>i:nth-child(20) {
    transition-delay: 0.4s;
  }
  
  .stripe-btn:hover .stripes>i {
    opacity: 0.5;
  }
  
  .futuristic-button {
    /* Other styling for the button */
    display: inline-block;
    padding: 10px 20px;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.2s ease;
    background-color: #e0706c00;
  }
  
  .futuristic-button:hover {
    background-color: #ff0;
    box-shadow: 0 0 10px #ff0, 0 0 40px #ff0, 0 0 80px #ff0;
    color: #000;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  
  .announcement {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 40vh;
  }
  
  
  .announcementP1 {
    color: gray;
    font-size: 16px;
  }
  
  .announcementP2 {
    color: white;
    font-size: 22px;
  }
  
  /* Animation */
  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    color: gray;
    font-size: 16px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    -webkit-animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 130px;
    }
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 130px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-3 {
    top: 50%;
    color: rgb(0, 194, 219);
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tw {
    margin-top: 40px;
  }
  
  .anim-typewriter3 {
    -webkit-animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 350px;
    }
  }
  
  @keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 350px;
    }
  }
  
  .lineP {
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .futuristic-text {
    /* Other styling for the text */
    position: relative;
    overflow: hidden;
  }
  
  .futuristic-text:before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #ff0 0%, #f00 100%);
  }
  
  @-webkit-keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  .futuristic-text:after {
    content: '|';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #ff0;
    -webkit-animation: typing 1s steps(40, end);
            animation: typing 1s steps(40, end);
  }
  
  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    letter-spacing: 1.8px;
    width: 130px;
    height: 50px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 16px;
  }
  
  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }
  
  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }
  
  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }
  
  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }
  
  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }
  
  .kave-btn:hover .kave-line:after {
    top: 0;
  }
  
  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .header {
    width: 350px;
    height: 162px;
  }
  
  .mintP {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 30px;
    color: white;
    font-size: 27px;
  }
  
  .pic {
    /* 
      width: 25%; 
      position: sticky;
      position: -webkit-sticky;
      left: 100%;
      margin-top: -30%;
      bottom: 0;
      animation: Slide_Left_Right 16.25s ease infinite, Opacity 16.25s ease infinite;*/
    -webkit-transform: rotate(-27deg);
            transform: rotate(-27deg);
    width: 40%;
    position: sticky;
    position: -webkit-sticky;
    margin-top: -30%;
    bottom: 0;
    margin-left: 85%;
    margin-top: -80%;
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  .back {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    z-index: 100000000;
  }
  
  .backDiv {
    z-index: 1000000000000000000;
  }
  
  .blur {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .v2Quote {
    text-align: center;
    color: white;
  
    border: 2px #facc15 solid;
    position: fixed;
    padding: 15px;
    font-size: 30px;
    top: 50%;
    left: 50%;
    z-index: 100000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  
  }
  
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: rgb(12, 17, 40);
    background: linear-gradient(99deg, rgba(12, 17, 40, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(10, 33, 23, 1) 94%);
  }
  
  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 30%;
  }
  
  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 200px;
    padding: 20px;
  
  }
  
  .homeBtn button:hover {
    margin-bottom: 2%;
  }
  
  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 2%;
  }
  
  .homeImages1 {
    -webkit-animation: Slide_Down1 6s ease infinite;
            animation: Slide_Down1 6s ease infinite;
  }
  
  .homeImages2 {
    -webkit-animation: Slide_Down2 5s ease infinite;
            animation: Slide_Down2 5s ease infinite;
  }
  
  @-webkit-keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @-webkit-keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  
  .right {
    width: 20%;
    margin-top: -70%;
  }
  
  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
  
  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
  }
  
  .emailSectionH {
    color: black;
  
    font-size: 20px;
    margin-bottom: 10px;
    color: #facc15;
  }
  
  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }
  
  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #a58503;
  }
  
  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    border: none;
    width: 150px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;
  }
  
  .step {
    color: white;
    font-size: 15px;
  }
  
  .connect2Mob {
    display: none;
  }
  
  .tableMobile {
    display: none;
  }
  
  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }
  
  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  
  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;
  
  }
  
  .view2 {
    margin-left: 5%;
    width: 20%;
  }
  
  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .view {
    margin-right: 5%;
  }
  
  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }
  
  .orders {
    margin-top: 1%;
  }
  
  
  
  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  
  }
  
  .hideTable {
    display: none;
  }
  
  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  
  .adminBtns button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  
  
  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }
  
  .btnsMainMob {
    display: none;
  }
  
  .stakeWC {
    background-color: gold;
    border-style: none;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .home:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakeWC:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .navBarStake {
    justify-content: center;
    margin-bottom: 2%;
  
  }
  
  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    display: flex;
  
  }
  
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }
  
  .v1 {
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
  
  
  .btns {
    z-index: 10000;
    width: 150px;
    background-color: #586285;
    color: white;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: ease-in 0.5s;
  }
  
  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns2 {
    width: 150px;
    color: white;
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #586285;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    transition: ease-in 0.5s;
  
  }
  
  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btns2:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }
  
  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #60e1f8, #006c80);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background: url(/static/media/Container.64f3b4d9.png) no-repeat center center fixed;
    background-size: cover;
    padding: 15px;
  }
  
  .markBarCon {
    background: rgba(172, 172, 172, 0.158);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    -webkit-clip-path: polygon(17% 0, 100% 0, 100% 73%, 85% 100%, 0 100%, 0 28%);
            clip-path: polygon(17% 0, 100% 0, 100% 73%, 85% 100%, 0 100%, 0 28%);
  }
  
  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }
  
  .num {
    font-size: 17px;
    color: #bdf1f5;
    text-align: center;
  }
  
  .name {
    color: #bdf1f5;
    font-size: 15px;
    text-align: center;
  
  }
  
  .num2 {
    font-size: 20px;
    color: white;
  
    text-align: center;
  }
  
  .name2 {
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }
  
  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }
  
  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/IuNL.e000bf3c.gif) no-repeat center center fixed;
    background-size: cover;
  }
  
  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  
  }
  
  .sticky {
    width: 20%;
  
    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }
  
  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 35px;
    border: #ffffff 4px solid;
    box-shadow: -10px 10px rgba(75, 75, 75, 0.842);
  
    margin-left: 1%;
    margin-right: 1%;
  
  }
  
  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  
  .nameStake {
    width: 50%;
  }
  
  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .boardImg {
    background-color: #ffb963;
  
  }
  
  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }
  
  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }
  
  .approveBtns1 {
    border: 4px solid;
    background-color: rgba(206, 8, 8, 0);
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 18px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  
  }
  
  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);
  
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
  
    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
  
  }
  
  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }
  
  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }
  
  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;
  
  }
  
  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
  
  .logo2 {
    width: 20%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
  
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
  
  
  .rmPic img {
    width: 100%;
    background-size: cover;
  }
  
  .rmMain {
    margin-top: 10%;
  }
  
  .unstakeImgM {
    display: none;
  }
  
  .formStake {
    z-index: 1;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .light {
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .mintMain {
    background: linear-gradient(100deg, rgba(11,18,32,1) 0%, rgba(0,7,20,0.7314523221397935) 69%, rgba(11,18,32,1) 98%), url(/static/media/grid_0.a399d903.png) no-repeat center center fixed;
    background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }
  
  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }
  
  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }
  
  
  @-webkit-keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 470px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    margin-bottom: 70px;
    box-shadow: 0 0 20px #00d6fc;
    margin-top: -80px;
 backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  
  }
  
  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  #fontSize {
    font-size: 16px;
    color: white;
    z-index: 1;
  }
  
  #fontSize:hover {
    color: #00E6F6;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  #fontSizeActive {
    font-size: 16px;
    color: #00E6F6;
   }
  
  #fontSizeActive:hover {
    color: white;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    background-color: #4bbdff;
  }
  
  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    background-color: #4bbdff;
  
    cursor: pointer;
  
  }
  
  .btnfos-0-2-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    border: none;
    width: 200px;
    border-top-left-radius: 10px;
   }
  
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #004dff;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0   var(--c),
      calc(-1 * -0.08em)  .01em 2px #0004;
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }
  
  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }
  
  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }
  
  .button-92 {
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
    color: #44a06c;
    font-size: 70px;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;
  
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #00FF00
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;
  
    /* border-left: #2d363600;
    border-right: #2d363600;*/
  }
  
  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    z-index: 100000;
    margin-top: -120px;

  }
  
  .wallet2 {
    background-color: #d79f6000;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
  
  }
  
  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  
  }
  
  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  .wallet3::after {
    transition: all 0.8s
  }
  
  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: #00E6F6;
    padding-bottom: 5%;
    z-index: 100000000;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    position: static;
    margin-bottom: 5%;
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  
  
  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .images_show {
    width: 90%;
  }
  
  .hide {
    display: none;
  }
  
  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }
  

  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
   }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }
  
  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .imagesAndBtn {
    justify-content: center;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }
  
  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  
  }
  
  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }
  
  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  
  .formMain1-1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }
  
  .ownNFT {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
  
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .discordB {
    display: none;
  }
  
  .medal {
    width: 35px;
    height: 35px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }
  
  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }
  
  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .boxWrapC {
    margin-top: 10%;
  }
  
  .mainTB {
    text-align: center;
    color: white;
  
    font-size: 50px;
  
  }
  
  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }
  
  .sT {
    text-align: center;
    color: white;
  
    font-size: 25px;
    margin-bottom: 1%;
  }
  
  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }
  
  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .footer2 img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 50px;
  
    cursor: pointer;
  }
  
  .stakeT {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  
    letter-spacing: 2px;
  }
  
  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .winnerT {
    text-align: center;
    color: #facc15;
  
    font-size: 50px;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
  }
  
  table {
    border-collapse: collapse;
    width: 80%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }
  
  .bg {
    background-color: #facc15;
    color: black;
  }
  
  
  
  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .imageSet img {
    width: 30%;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
  }
  
  .imageSet img:hover {
    width: 30%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .logoNFT {
    width: 120px;
    height: 120px;
    z-index: 1;
  }
  
  .glitch {
    color: white;
    font-size: 100px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  
  @-webkit-keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  
  @keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  @-webkit-keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  
  @keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  .glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  .aboutPageMain2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .aboutP{
    color: rgb(197, 247, 247);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    text-align: justify;
  }

  .aboutDiv{
    color: rgb(197, 247, 247);
    width: 80%;
    color: #00d6fc;
    margin-bottom: 60px;
    text-align: justify;
    justify-content: flex-start;
  }
  
  .cMain{
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain img{
    width: 70px;
    height: 70px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain div{
    color: rgb(167, 167, 167);
    text-align: center;
    margin-top: -12px;
    margin-bottom: 30px;
  }
  
  .cLine{
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .beast{
    width: 330px;
    height: 330px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 10000000000000;
   }
  
  .price{
    text-align: center;
    color: rgb(0, 250, 167);
  }
  
  .nftminter2{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: #b8eff8;
    font-size: 40px;
  }
  
  .nftminter2Div{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .center{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .placeAndWL{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 130px;
    margin-top: -50px;
  }
  
  /* CSS */
  .button-89 {
    --b: 3px;   /* border thickness */
    --s: .45em; /* size of the corner */
    --color: #ffffff;
    
    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 16px;
  
    border: 0;
  
    -ms-user-select: none;
  
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-89:hover,
  .button-89:focus-visible{
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }
  
  .button-89:active {
    background: var(--color);
    color: #fff;
  }
  
  input{
    border: white 2px solid;
    font-size: 16px;
    padding: 6px;
    margin-right: 20px;
    background-color: rgba(0, 0, 0, 0.432);
    color: #00e1ff;
  }
  
  
  .px-app,
  .px-app *,
  .px-app *::before,
  .px-app *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  .px-glitch {
    text-align: center;
    color: white;
  }
  
  .px-glitchtext {
    font-size: 5rem;
    font-weight: 600;
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    display: block;
  }
  .px-glitchtext-anim {
    -webkit-animation: glitchtext 300ms linear infinite;
            animation: glitchtext 300ms linear infinite;
  }
  @-webkit-keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  @keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  
  .px-glitchsvg {
    font-size: 4rem;
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
  .px-glitchsvg-anim {
    -webkit-animation: glitchsvg 200ms none infinite;
            animation: glitchsvg 200ms none infinite;
  }
  @-webkit-keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  @keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  
  .csPage{
    margin-top: 20vh;
  }
  
  :root {
    --color-bg: #eaeaea;
    --color-loader: #222;
    --loader-size: 100px;
    --loader-speed: 500ms;
  }
  
  .loadMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .loader {
    --loader-height: calc(var(--loader-size) / 7);
    display: flex;
    position: relative;
  }
  .loader::before {
    content: '';
    position: absolute;
  }
  .loader::before,
  .loader > span {
    display: block;
    width: var(--loader-height);
    height: var(--loader-height);
    border-radius: 50%;
    background: var(--color-loader);
    background-color: #516466;
    -webkit-animation-name: to-right;
            animation-name: to-right;
    -webkit-animation-duration: var(--loader-speed);
            animation-duration: var(--loader-speed);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .loader > span:nth-child(even) {
    visibility: hidden;
  }
  .loader > span:first-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
  }
  .loader > span:last-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
    animation-direction: reverse;
  }
  
  @-webkit-keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  
  @keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  @-webkit-keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
}

@media screen and (min-width: 1920px) {
  .connectMobile {
    display: none;
  }
  .walletAndMint {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .mintP:hover {
    color: gold;
    cursor: pointer;
  }
  
  .btnRed1 {
    margin-right: 40px;
  }
  
  .btnRed2 {
    margin-left: 40px;
  }
  
  :root {
    --primary: #4f5877;
    --secondary: hsl(224, 20%, 17%);
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
  .button-49-2,
  .button-49-2:after {
    width: 400px;
    height: 100px;
    line-height: 78px;
    font-size: 30px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: 0.5s ease-in;
  }
  
  .button-49-2:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49-2:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
    opacity: 0;
  }

  .button-49-2:hover span {
    display:none
  }
  
  .button-49-2:hover:before {
    content:"COMING SOON";
  }
  
  .button-49,
  .button-49:after {
    width: 280px;
    height: 76px;
    line-height: 78px;
    font-size: 30px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
  }
  
  @-webkit-keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 400px;
      height: 100px;
      line-height: 88px;
    }
  }
  
  .stakePagesBtns {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 20vh;
  }
  .stakePagesBtns2 {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 70px;
  }
  .stakeMainPage {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .stakeMainPage2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/futuristic-gif-9.73bec76a.gif) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .csMAin {
    background: rgb(0,28,32);
    background: linear-gradient(100deg, rgba(0,28,32,1) 0%, rgba(0,24,27,1) 69%, rgba(0,28,32,1) 98%);
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .aboutPageMain {
    background: rgb(0,30,33);
    background: linear-gradient(355deg, rgba(0,30,33,1) 0%, rgba(0,0,0,1) 69%, rgba(0,42,47,1) 98%);
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .stripe-btn {
    --text-shadow-thickness: 0.05ch;
    --text-shadow-negative-thickness: calc(-1 * var(--text-shadow-thickness));
    position: relative;
    border: 2px solid var(--primary);
    font-size: 3rem;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    color: var(--primary);
    text-transform: uppercase;
    text-shadow:
      var(--text-shadow-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary);
    width: 280px;
    text-align: center;
  }
  
  .corners {
    --corner-dimension: 0.7ch;
    --corner-thickness: 3px;
    --corner-negative-thickness: calc(-1 * var(--corner-thickness));
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .corners>i {
    content: "";
    position: absolute;
    width: var(--corner-dimension);
    height: var(--corner-dimension);
  }
  
  .corners>i:nth-child(1) {
    top: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(2) {
    top: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(3) {
    bottom: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(4) {
    bottom: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .stripes {
    --stripes-position: 0.3ch;
    content: "";
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: calc(100% - var(--stripes-position) * 2);
    width: calc(100% - var(--stripes-position) * 2);
    top: var(--stripes-position);
    left: var(--stripes-position);
    overflow: hidden;
  }
  
  .stripes>i {
    content: "";
    height: calc(100% + 2ch);
    width: 0.5ch;
    background: var(--primary);
    opacity: 0.3;
    -webkit-transform: translateY(-1ch) rotate(37deg);
            transform: translateY(-1ch) rotate(37deg);
    transition: all 0.2s cubic-bezier(0.85, 0, 0.15, 1)
  }
  
  .stripes>i:nth-child(1) {
    transition-delay: 0.02s;
  }
  
  .stripes>i:nth-child(2) {
    transition-delay: 0.04s;
  }
  
  .stripes>i:nth-child(3) {
    transition-delay: 0.06s;
  }
  
  .stripes>i:nth-child(4) {
    transition-delay: 0.08s;
  }
  
  .stripes>i:nth-child(5) {
    transition-delay: 0.10s;
  }
  
  .stripes>i:nth-child(6) {
    transition-delay: 0.12s;
  }
  
  .stripes>i:nth-child(7) {
    transition-delay: 0.14s;
  }
  
  .stripes>i:nth-child(8) {
    transition-delay: 0.16s;
  }
  
  .stripes>i:nth-child(9) {
    transition-delay: 0.18s;
  }
  
  .stripes>i:nth-child(10) {
    transition-delay: 0.20s;
  }
  
  .stripes>i:nth-child(11) {
    transition-delay: 0.22s;
  }
  
  .stripes>i:nth-child(12) {
    transition-delay: 0.24s;
  }
  
  .stripes>i:nth-child(13) {
    transition-delay: 0.26s;
  }
  
  .stripes>i:nth-child(14) {
    transition-delay: 0.28s;
  }
  
  .stripes>i:nth-child(15) {
    transition-delay: 0.3s;
  }
  
  .stripes>i:nth-child(16) {
    transition-delay: 0.32s;
  }
  
  .stripes>i:nth-child(17) {
    transition-delay: 0.34s;
  }
  
  .stripes>i:nth-child(18) {
    transition-delay: 0.36s;
  }
  
  .stripes>i:nth-child(19) {
    transition-delay: 0.38s;
  }
  
  .stripes>i:nth-child(20) {
    transition-delay: 0.4s;
  }
  
  .stripe-btn:hover .stripes>i {
    opacity: 0.5;
  }
  
  .futuristic-button {
    /* Other styling for the button */
    display: inline-block;
    padding: 20px 30px;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.2s ease;
    background-color: #e0706c00;
    font-size: 30px;
  }
  
  .futuristic-button:hover {
    background-color: #ff0;
    box-shadow: 0 0 10px #ff0, 0 0 40px #ff0, 0 0 80px #ff0;
    color: #000;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  
  .announcement {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 50vh;
  }
  
  
  .announcementP1 {
    color: gray;
    font-size: 16px;
  }
  
  .announcementP2 {
    color: white;
    font-size: 32px;
  }
  
  /* Animation */
  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    color: gray;
    font-size: 25px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    -webkit-animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 205px;
    }
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 205px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-3 {
    top: 50%;
    color: rgb(0, 194, 219);
    font-size: 27px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tw {
    margin-top: 40px;
  }
  
  .anim-typewriter3 {
    -webkit-animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 525px;
    }
  }
  
  @keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 525px;
    }
  }
  
  .lineP {
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .futuristic-text {
    /* Other styling for the text */
    position: relative;
    overflow: hidden;
  }
  
  .futuristic-text:before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #ff0 0%, #f00 100%);
  }
  
  @-webkit-keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  .futuristic-text:after {
    content: '|';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #ff0;
    -webkit-animation: typing 1s steps(40, end);
            animation: typing 1s steps(40, end);
  }
  
  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    letter-spacing: 1.8px;
    width: 160px;
    height: 60px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 20px;
  }
  
  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }
  
  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }
  
  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }
  
  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }
  
  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }
  
  .kave-btn:hover .kave-line:after {
    top: 0;
  }
  
  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .header {
    width: 350px;
    height: 162px;
  }
  
  .mintP {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 30px;
    color: white;
    font-size: 27px;
  }
  
  .pic {
    /* 
      width: 25%; 
      position: sticky;
      position: -webkit-sticky;
      left: 100%;
      margin-top: -30%;
      bottom: 0;
      animation: Slide_Left_Right 16.25s ease infinite, Opacity 16.25s ease infinite;*/
    -webkit-transform: rotate(-27deg);
            transform: rotate(-27deg);
    width: 40%;
    position: sticky;
    position: -webkit-sticky;
    margin-top: -30%;
    bottom: 0;
    margin-left: 85%;
    margin-top: -80%;
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  .back {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    z-index: 100000000;
  }
  
  .backDiv {
    z-index: 1000000000000000000;
  }
  
  .blur {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .v2Quote {
    text-align: center;
    color: white;
  
    border: 2px #facc15 solid;
    position: fixed;
    padding: 15px;
    font-size: 30px;
    top: 50%;
    left: 50%;
    z-index: 100000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  
  }
  
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: rgb(12, 17, 40);
    background: linear-gradient(99deg, rgba(12, 17, 40, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(10, 33, 23, 1) 94%);
  }
  
  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 30%;
  }
  
  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 200px;
    padding: 20px;
  
  }
  
  .homeBtn button:hover {
    margin-bottom: 2%;
  }
  
  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 2%;
  }
  
  .homeImages1 {
    -webkit-animation: Slide_Down1 6s ease infinite;
            animation: Slide_Down1 6s ease infinite;
  }
  
  .homeImages2 {
    -webkit-animation: Slide_Down2 5s ease infinite;
            animation: Slide_Down2 5s ease infinite;
  }
  
  @-webkit-keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @-webkit-keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  
  .right {
    width: 20%;
    margin-top: -70%;
  }
  
  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
  
  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
  }
  
  .emailSectionH {
    color: black;
  
    font-size: 20px;
    margin-bottom: 10px;
    color: #facc15;
  }
  
  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }
  
  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #a58503;
  }
  
  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    border: none;
    width: 150px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;
  }
  
  .step {
    color: white;
    font-size: 15px;
  }
  
  .connect2Mob {
    display: none;
  }
  
  .tableMobile {
    display: none;
  }
  
  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }
  
  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  
  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;
  
  }
  
  .view2 {
    margin-left: 5%;
    width: 20%;
  }
  
  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .view {
    margin-right: 5%;
  }
  
  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }
  
  .orders {
    margin-top: 1%;
  }
  
  
  
  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  
  }
  
  .hideTable {
    display: none;
  }
  
  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  
  .adminBtns button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  
  
  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }
  
  .btnsMainMob {
    display: none;
  }
  
  .stakeWC {
    background-color: gold;
    border-style: none;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .home:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakeWC:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .navBarStake {
    justify-content: center;
    margin-bottom: 2%;
  
  }
  
  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    display: flex;
  
  }
  
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }
  
  .v1 {
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
  
  
  .btns {
    z-index: 10000;
    width: 150px;
    background-color: #586285;
    color: white;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: ease-in 0.5s;
    font-size: 20px;
  }
  
  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns2 {
    width: 150px;
    color: white;
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #586285;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    transition: ease-in 0.5s;
    font-size: 20px;

  }
  
  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btns2:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }
  
  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #60e1f8, #006c80);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background: url(/static/media/Container.64f3b4d9.png) no-repeat center center fixed;
    background-size: cover;
    padding: 15px;
  }
  
  .markBarCon {
    background: rgba(172, 172, 172, 0.158);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 10px;
    -webkit-clip-path: polygon(17% 0, 100% 0, 100% 73%, 85% 100%, 0 100%, 0 28%);
            clip-path: polygon(17% 0, 100% 0, 100% 73%, 85% 100%, 0 100%, 0 28%);
  }
  
  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }
  
  .num {
    font-size: 20px;
    color: #bdf1f5;
    text-align: center;
  }
  
  .name {
    color: #bdf1f5;
    font-size: 18px;
    text-align: center;
  
  }
  
  .num2 {
    font-size: 20px;
    color: white;
  
    text-align: center;
  }
  
  .name2 {
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }
  
  .markBar img {
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }
  
  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/IuNL.e000bf3c.gif) no-repeat center center fixed;
    background-size: cover;
  }
  
  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  
  }
  
  .sticky {
    width: 20%;
  
    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }
  
  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 55px;
    border: #ffffff 4px solid;
    box-shadow: -10px 10px rgba(75, 75, 75, 0.842);
  
    margin-left: 1%;
    margin-right: 1%;
  
  }
  
  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 60%; 
  }
  
  .nameStake {
    width: 50%;
  }
  
  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .boardImg {
    background-color: #ffb963;
  
  }
  
  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }
  
  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }
  
  .approveBtns1 {
    border: 4px solid;
    background-color: rgba(206, 8, 8, 0);
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 18px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  
  }
  
  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);
  
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
  
    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
  
  }
  
  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }
  
  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }
  
  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;
  
  }
  
  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
  
  .logo2 {
    width: 20%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
  
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
  
  
  .rmPic img {
    width: 100%;
    background-size: cover;
  }
  
  .rmMain {
    margin-top: 10%;
  }
  
  .unstakeImgM {
    display: none;
  }
  
  .formStake {
    z-index: 1;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .light {
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .mintMain {
    background: linear-gradient(100deg, rgba(11,18,32,1) 0%, rgba(0,7,20,0.7314523221397935) 69%, rgba(11,18,32,1) 98%), url(/static/media/grid_0.a399d903.png) no-repeat center center fixed;
    background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }
  
  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }
  
  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }
  
  
  @-webkit-keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    margin-bottom: 70px;
    box-shadow: 0 0 20px #00d6fc;
    margin-top: -130px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
      }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .successfully {
    margin-top: 1%;
    font-size: 25px;
    color: #000000;
    text-align: center;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  
  }
  
  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  #fontSize {
    font-size: 21px;
    color: white;
    z-index: 1;

  }
  
  #fontSize:hover {
    color: #00E6F6;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  #fontSizeActive {
    font-size: 16px;
    color: #00E6F6;
    font-size: 21px;

   }
  
  #fontSizeActive:hover {
    color: white;
    cursor: pointer;
    transition: ease 0.5s;
    
  }
  
  
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    height: 60px;
    width: 60px;
    border: none;
    cursor: pointer;
    background-color: #4bbdff;
    z-index: 10000;
  }
  
  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    height: 60px;
    width: 60px;
    border: none;
    background-color: #4bbdff;
  
    cursor: pointer;
  
  }
  
  .btnfos-0-2-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    border: none;
    width: 200px;
    border-top-left-radius: 10px;
   }
  
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #004dff;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0   var(--c),
      calc(-1 * -0.08em)  .01em 2px #0004;
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }
  
  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }
  
  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }
  
  .button-92 {
    font-weight: bold;
    font-size: 2.5rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
    color: #44a06c;
    font-size: 80px;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;
  
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #00FF00
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;
  
    /* border-left: #2d363600;
    border-right: #2d363600;*/
  }
  
  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-top: -180px;

  }
  
  .wallet2 {
    background-color: #d79f6000;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
  
  }
  
  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  
  }
  
  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  .wallet3::after {
    transition: all 0.8s
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: #00E6F6;
    padding-bottom: 5%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    position: static;
    margin-bottom: 5%;
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  
  
  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .images_show {
    width: 90%;
  }
  
  .hide {
    display: none;
  }
  
  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }
  

  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }
  
  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .imagesAndBtn {
    justify-content: center;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }
  
  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  
  }
  
  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }
  
  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  
  .formMain1-1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }
  
  .ownNFT {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
  
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .discordB {
    display: none;
  }
  
  .medal {
    width: 35px;
    height: 35px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }
  
  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }
  
  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .boxWrapC {
    margin-top: 10%;
  }
  
  .mainTB {
    text-align: center;
    color: white;
  
    font-size: 50px;
  
  }
  
  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }
  
  .sT {
    text-align: center;
    color: white;
  
    font-size: 25px;
    margin-bottom: 1%;
  }
  
  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }
  
  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .footer2 img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 50px;
  
    cursor: pointer;
  }
  
  .stakeT {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  
    letter-spacing: 2px;
  }
  
  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .winnerT {
    text-align: center;
    color: #facc15;
  
    font-size: 50px;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
  }
  
  table {
    border-collapse: collapse;
    width: 80%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }
  
  .bg {
    background-color: #facc15;
    color: black;
  }
  
  
  
  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .imageSet img {
    width: 30%;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
  }
  
  .imageSet img:hover {
    width: 30%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .logoNFT {
    width: 140px;
    height: 140px;
    z-index: 1;

  }
  
  .glitch {
    color: white;
    font-size: 150px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  
  @-webkit-keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  
  @keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  @-webkit-keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  
  @keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  .glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  .aboutPageMain2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .aboutP{
    color: rgb(197, 247, 247);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    text-align: justify;
    font-size: 22px;
  }

  .aboutDiv{
    color: rgb(197, 247, 247);
    width: 80%;
    color: #00d6fc;
    margin-bottom: 60px;
    text-align: justify;
    justify-content: flex-start;
    font-size: 22px;
  }
  
  
  .cMain{
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain img{
    width: 90px;
    height: 90px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain div{
    color: rgb(167, 167, 167);
    text-align: center;
    margin-top: -12px;
    margin-bottom: 30px;
    font-size: 20px;
  }
  
  .cLine{
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .beast{
    width: 550px;
    height: 550px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
   }
  
  .price{
    text-align: center;
    color: rgb(0, 250, 167);
    font-size: 22px;
  }
  
  .nftminter2{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: #b8eff8;
    font-size: 55px;
  }
  
  .nftminter2Div{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .center{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .placeAndWL{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 180px;
    margin-top: -150px;  }
  
  /* CSS */
  .button-89 {
    --b: 3px;   /* border thickness */
    --s: .45em; /* size of the corner */
    --color: #ffffff;
    
    padding: calc(.6em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 22px;
  
    border: 0;
  
    -ms-user-select: none;
  
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-89:hover,
  .button-89:focus-visible{
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }
  
  .button-89:active {
    background: var(--color);
    color: #fff;
  }
  
  input{
    border: white 2px solid;
    font-size: 20px;
    padding: 10px;
    margin-right: 22px;
    background-color: rgba(0, 0, 0, 0.432);
    color: #00e1ff;
  }
  
  
  .px-app,
  .px-app *,
  .px-app *::before,
  .px-app *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  .px-glitch {
    text-align: center;
    color: white;
  }
  
  .px-glitchtext {
    font-size: 8rem;
    font-weight: 600;
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    display: block;
  }
  .px-glitchtext-anim {
    -webkit-animation: glitchtext 300ms linear infinite;
            animation: glitchtext 300ms linear infinite;
  }
  @-webkit-keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  @keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  
  .px-glitchsvg {
    font-size: 4rem;
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
  .px-glitchsvg-anim {
    -webkit-animation: glitchsvg 200ms none infinite;
            animation: glitchsvg 200ms none infinite;
  }
  @-webkit-keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  @keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  
  .csPage{
    margin-top: 25vh;
  }
  
  :root {
    --color-bg: #eaeaea;
    --color-loader: #222;
    --loader-size: 100px;
    --loader-speed: 500ms;
  }
  
  .loadMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .loader {
    --loader-height: calc(var(--loader-size) / 4);
    display: flex;
    position: relative;
  }
  .loader::before {
    content: '';
    position: absolute;
  }
  .loader::before,
  .loader > span {
    display: block;
    width: var(--loader-height);
    height: var(--loader-height);
    border-radius: 50%;
    background: var(--color-loader);
    background-color: #516466;
    -webkit-animation-name: to-right;
            animation-name: to-right;
    -webkit-animation-duration: var(--loader-speed);
            animation-duration: var(--loader-speed);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .loader > span:nth-child(even) {
    visibility: hidden;
  }
  .loader > span:first-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
  }
  .loader > span:last-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
    animation-direction: reverse;
  }
  
  @-webkit-keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  
  @keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  @-webkit-keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .connectMobile {
    display: none;
  }
  .walletAndMint {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .mintP:hover {
    color: gold;
    cursor: pointer;
  }
  
  .btnRed1 {
    margin-right: 40px;
  }
  
  .btnRed2 {
    margin-left: 40px;
  }
  
  :root {
    --primary: #4f5877;
    --secondary: hsl(224, 20%, 17%);
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
  .button-49-2,
  .button-49-2:after {
    width: 600px;
    height: 150px;
    line-height: 88px;
    font-size: 45px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: 0.5s ease-in;
  }
  
  .button-49-2:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49-2:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
    opacity: 0;
  }

  .button-49-2:hover span {
    display:none
  }
  
  .button-49-2:hover:before {
    content:"COMING SOON";
  }
  
  .button-49,
  .button-49:after {
    width: 280px;
    height: 76px;
    line-height: 78px;
    font-size: 45px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
  }
  
  @-webkit-keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 600px;
      height: 150px;
      line-height: 88px;
    }
  }
  
  .stakePagesBtns {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 20vh;
  }
  .stakePagesBtns2 {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 100px;
  }
  
  .stakeMainPage {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .stakeMainPage2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/futuristic-gif-9.73bec76a.gif) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .csMAin {
    background: rgb(0,28,32);
    background: linear-gradient(100deg, rgba(0,28,32,1) 0%, rgba(0,24,27,1) 69%, rgba(0,28,32,1) 98%);
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .aboutPageMain {
    background: rgb(0,30,33);
    background: linear-gradient(355deg, rgba(0,30,33,1) 0%, rgba(0,0,0,1) 69%, rgba(0,42,47,1) 98%);
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .stripe-btn {
    --text-shadow-thickness: 0.05ch;
    --text-shadow-negative-thickness: calc(-1 * var(--text-shadow-thickness));
    position: relative;
    border: 2px solid var(--primary);
    font-size: 3rem;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    color: var(--primary);
    text-transform: uppercase;
    text-shadow:
      var(--text-shadow-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary);
    width: 280px;
    text-align: center;
  }
  
  .corners {
    --corner-dimension: 0.7ch;
    --corner-thickness: 3px;
    --corner-negative-thickness: calc(-1 * var(--corner-thickness));
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .corners>i {
    content: "";
    position: absolute;
    width: var(--corner-dimension);
    height: var(--corner-dimension);
  }
  
  .corners>i:nth-child(1) {
    top: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(2) {
    top: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(3) {
    bottom: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(4) {
    bottom: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .stripes {
    --stripes-position: 0.3ch;
    content: "";
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: calc(100% - var(--stripes-position) * 2);
    width: calc(100% - var(--stripes-position) * 2);
    top: var(--stripes-position);
    left: var(--stripes-position);
    overflow: hidden;
  }
  
  .stripes>i {
    content: "";
    height: calc(100% + 2ch);
    width: 0.5ch;
    background: var(--primary);
    opacity: 0.3;
    -webkit-transform: translateY(-1ch) rotate(37deg);
            transform: translateY(-1ch) rotate(37deg);
    transition: all 0.2s cubic-bezier(0.85, 0, 0.15, 1)
  }
  
  .stripes>i:nth-child(1) {
    transition-delay: 0.02s;
  }
  
  .stripes>i:nth-child(2) {
    transition-delay: 0.04s;
  }
  
  .stripes>i:nth-child(3) {
    transition-delay: 0.06s;
  }
  
  .stripes>i:nth-child(4) {
    transition-delay: 0.08s;
  }
  
  .stripes>i:nth-child(5) {
    transition-delay: 0.10s;
  }
  
  .stripes>i:nth-child(6) {
    transition-delay: 0.12s;
  }
  
  .stripes>i:nth-child(7) {
    transition-delay: 0.14s;
  }
  
  .stripes>i:nth-child(8) {
    transition-delay: 0.16s;
  }
  
  .stripes>i:nth-child(9) {
    transition-delay: 0.18s;
  }
  
  .stripes>i:nth-child(10) {
    transition-delay: 0.20s;
  }
  
  .stripes>i:nth-child(11) {
    transition-delay: 0.22s;
  }
  
  .stripes>i:nth-child(12) {
    transition-delay: 0.24s;
  }
  
  .stripes>i:nth-child(13) {
    transition-delay: 0.26s;
  }
  
  .stripes>i:nth-child(14) {
    transition-delay: 0.28s;
  }
  
  .stripes>i:nth-child(15) {
    transition-delay: 0.3s;
  }
  
  .stripes>i:nth-child(16) {
    transition-delay: 0.32s;
  }
  
  .stripes>i:nth-child(17) {
    transition-delay: 0.34s;
  }
  
  .stripes>i:nth-child(18) {
    transition-delay: 0.36s;
  }
  
  .stripes>i:nth-child(19) {
    transition-delay: 0.38s;
  }
  
  .stripes>i:nth-child(20) {
    transition-delay: 0.4s;
  }
  
  .stripe-btn:hover .stripes>i {
    opacity: 0.5;
  }
  
  .futuristic-button {
    /* Other styling for the button */
    display: inline-block;
    padding: 20px 30px;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.2s ease;
    background-color: #e0706c00;
    font-size: 40px;
  }
  
  .futuristic-button:hover {
    background-color: #ff0;
    box-shadow: 0 0 10px #ff0, 0 0 40px #ff0, 0 0 80px #ff0;
    color: #000;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  
  .announcement {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 52vh;
  }
  
  
  .announcementP1 {
    color: gray;
    font-size: 16px;
  }
  
  .announcementP2 {
    color: white;
    font-size: 40px;
  }
  
  /* Animation */
  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    color: gray;
    font-size: 30px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    -webkit-animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 245px;
    }
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 245px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-3 {
    top: 50%;
    color: rgb(0, 194, 219);
    font-size: 40px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tw {
    margin-top: 40px;
  }
  
  .anim-typewriter3 {
    -webkit-animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 780px;
    }
  }
  
  @keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 780px;
    }
  }
  
  .lineP {
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .futuristic-text {
    /* Other styling for the text */
    position: relative;
    overflow: hidden;
  }
  
  .futuristic-text:before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #ff0 0%, #f00 100%);
  }
  
  @-webkit-keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  .futuristic-text:after {
    content: '|';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #ff0;
    -webkit-animation: typing 1s steps(40, end);
            animation: typing 1s steps(40, end);
  }
  
  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    letter-spacing: 1.8px;
    width: 230px;
    height: 90px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 30px;
  }
  
  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }
  
  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }
  
  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }
  
  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }
  
  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }
  
  .kave-btn:hover .kave-line:after {
    top: 0;
  }
  
  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .header {
    width: 350px;
    height: 162px;
  }
  
  .mintP {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 30px;
    color: white;
    font-size: 27px;
  }
  
  .pic {
    /* 
      width: 25%; 
      position: sticky;
      position: -webkit-sticky;
      left: 100%;
      margin-top: -30%;
      bottom: 0;
      animation: Slide_Left_Right 16.25s ease infinite, Opacity 16.25s ease infinite;*/
    -webkit-transform: rotate(-27deg);
            transform: rotate(-27deg);
    width: 40%;
    position: sticky;
    position: -webkit-sticky;
    margin-top: -30%;
    bottom: 0;
    margin-left: 85%;
    margin-top: -80%;
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  .back {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    z-index: 100000000;
  }
  
  .backDiv {
    z-index: 1000000000000000000;
  }
  
  .blur {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .v2Quote {
    text-align: center;
    color: white;
  
    border: 2px #facc15 solid;
    position: fixed;
    padding: 15px;
    font-size: 30px;
    top: 50%;
    left: 50%;
    z-index: 100000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  
  }
  
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: rgb(12, 17, 40);
    background: linear-gradient(99deg, rgba(12, 17, 40, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(10, 33, 23, 1) 94%);
  }
  
  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 30%;
  }
  
  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 200px;
    padding: 20px;
  
  }
  
  .homeBtn button:hover {
    margin-bottom: 2%;
  }
  
  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 2%;
  }
  
  .homeImages1 {
    -webkit-animation: Slide_Down1 6s ease infinite;
            animation: Slide_Down1 6s ease infinite;
  }
  
  .homeImages2 {
    -webkit-animation: Slide_Down2 5s ease infinite;
            animation: Slide_Down2 5s ease infinite;
  }
  
  @-webkit-keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @-webkit-keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  
  .right {
    width: 20%;
    margin-top: -70%;
  }
  
  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
  
  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
  }
  
  .emailSectionH {
    color: black;
  
    font-size: 20px;
    margin-bottom: 10px;
    color: #facc15;
  }
  
  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }
  
  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #a58503;
  }
  
  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    border: none;
    width: 150px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;
  }
  
  .step {
    color: white;
    font-size: 15px;
  }
  
  .connect2Mob {
    display: none;
  }
  
  .tableMobile {
    display: none;
  }
  
  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }
  
  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  
  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;
  
  }
  
  .view2 {
    margin-left: 5%;
    width: 20%;
  }
  
  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .view {
    margin-right: 5%;
  }
  
  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }
  
  .orders {
    margin-top: 1%;
  }
  
  
  
  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  
  }
  
  .hideTable {
    display: none;
  }
  
  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  
  .adminBtns button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  
  
  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }
  
  .btnsMainMob {
    display: none;
  }
  
  .stakeWC {
    background-color: gold;
    border-style: none;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .home:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakeWC:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .navBarStake {
    justify-content: center;
    margin-bottom: 2%;
  
  }
  
  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    display: flex;
  
  }
  
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }
  
  .v1 {
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
  
  
  .btns {
    z-index: 10000;
    width: 150px;
    background-color: #586285;
    color: white;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: ease-in 0.5s;
    font-size: 20px;
  }
  
  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns2 {
    width: 150px;
    color: white;
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #586285;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    transition: ease-in 0.5s;
    font-size: 20px;

  }
  
  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btns2:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }
  
  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #60e1f8, #006c80);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background: url(/static/media/Container.64f3b4d9.png) no-repeat center center fixed;
    background-size: cover;
    padding: 15px;
  }
  
  .markBarCon {
    background: rgba(172, 172, 172, 0.158);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 10px;
    -webkit-clip-path: polygon(17% 0, 100% 0, 100% 73%, 85% 100%, 0 100%, 0 28%);
            clip-path: polygon(17% 0, 100% 0, 100% 73%, 85% 100%, 0 100%, 0 28%);
  }
  
  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }
  
  .num {
    font-size: 20px;
    color: #bdf1f5;
    text-align: center;
  }
  
  .name {
    color: #bdf1f5;
    font-size: 18px;
    text-align: center;
  
  }
  
  .num2 {
    font-size: 20px;
    color: white;
  
    text-align: center;
  }
  
  .name2 {
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }
  
  .markBar img {
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }
  
  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/IuNL.e000bf3c.gif) no-repeat center center fixed;
    background-size: cover;
  }
  
  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  
  }
  
  .sticky {
    width: 20%;
  
    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }
  
  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 70px;
    border: #ffffff 4px solid;
    box-shadow: -10px 10px rgba(75, 75, 75, 0.842);
  
    margin-left: 1%;
    margin-right: 1%;
  
  }
  
  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 50%; 
  }
  
  .nameStake {
    width: 50%;
  }
  
  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .boardImg {
    background-color: #ffb963;
  
  }
  
  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }
  
  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }
  
  .approveBtns1 {
    border: 4px solid;
    background-color: rgba(206, 8, 8, 0);
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 18px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  
  }
  
  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);
  
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
  
    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
  
  }
  
  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }
  
  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }
  
  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;
  
  }
  
  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
  
  .logo2 {
    width: 20%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
  
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
  
  
  .rmPic img {
    width: 100%;
    background-size: cover;
  }
  
  .rmMain {
    margin-top: 10%;
  }
  
  .unstakeImgM {
    display: none;
  }
  
  .formStake {
    z-index: 1;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .light {
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .mintMain {
    background: linear-gradient(100deg, rgba(11,18,32,1) 0%, rgba(0,7,20,0.7314523221397935) 69%, rgba(11,18,32,1) 98%), url(/static/media/grid_0.a399d903.png) no-repeat center center fixed;
    background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }
  
  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }
  
  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }
  
  
  @-webkit-keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    margin-bottom: 70px;
    box-shadow: 0 0 20px #00d6fc;
    margin-top: -130px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
      }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 30px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .successfully {
    margin-top: 1%;
    font-size: 30px;
    color: #000000;
    text-align: center;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 28px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  
  }
  
  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }
  
  .connect div {
    margin-left: 17px;
    margin-right: 17px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  #fontSize {
    font-size: 30px;
    color: white;
    z-index: 1;

  }
  
  #fontSize:hover {
    color: #00E6F6;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  #fontSizeActive {
    font-size: 30px;
    color: #00E6F6;
   }
  
  #fontSizeActive:hover {
    color: white;
    cursor: pointer;
    transition: ease 0.5s;
    
  }
  
  
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    height: 80px;
    width: 80px;
    border: none;
    cursor: pointer;
    background-color: #4bbdff;
  }
  
  .btnfos-0-2-2 {
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    height: 80px;
    width: 80px;
    border: none;
    background-color: #4bbdff;
  
    cursor: pointer;
  
  }
  
  .btnfos-0-2-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    border: none;
    width: 200px;
    border-top-left-radius: 10px;
   }
  
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #004dff;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0   var(--c),
      calc(-1 * -0.08em)  .01em 2px #0004;
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }
  
  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }
  
  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }
  
  .button-92 {
    font-weight: bold;
    font-size: 3rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
    color: #44a06c;
    font-size: 80px;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;
  
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #00FF00
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;
  
    /* border-left: #2d363600;
    border-right: #2d363600;*/
  }
  
  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-top: -180px;

  }
  
  .wallet2 {
    background-color: #d79f6000;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
  
  }
  
  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  
  }
  
  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  .wallet3::after {
    transition: all 0.8s
  }
  
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: #00E6F6;
    padding-bottom: 5%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    position: static;
    margin-bottom: 5%;
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  
  
  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .images_show {
    width: 90%;
  }
  
  .hide {
    display: none;
  }
  
  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }
  

  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }
  
  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .imagesAndBtn {
    justify-content: center;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }
  
  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  
  }
  
  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }
  
  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  
  .formMain1-1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }
  
  .ownNFT {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
  
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .discordB {
    display: none;
  }
  
  .medal {
    width: 35px;
    height: 35px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }
  
  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }
  
  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .boxWrapC {
    margin-top: 10%;
  }
  
  .mainTB {
    text-align: center;
    color: white;
  
    font-size: 50px;
  
  }
  
  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }
  
  .sT {
    text-align: center;
    color: white;
  
    font-size: 25px;
    margin-bottom: 1%;
  }
  
  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }
  
  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .footer2 img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 50px;
  
    cursor: pointer;
  }
  
  .stakeT {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  
    letter-spacing: 2px;
  }
  
  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .winnerT {
    text-align: center;
    color: #facc15;
  
    font-size: 50px;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
  }
  
  table {
    border-collapse: collapse;
    width: 80%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }
  
  .bg {
    background-color: #facc15;
    color: black;
  }
  
  
  
  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .imageSet img {
    width: 30%;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
  }
  
  .imageSet img:hover {
    width: 30%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .logoNFT {
    width: 200px;
    height: 200px;
    z-index: 1;

  }
  
  .glitch {
    color: white;
    font-size: 200px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  
  @-webkit-keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  
  @keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  @-webkit-keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  
  @keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  .glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  .aboutPageMain2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .aboutP{
    color: rgb(197, 247, 247);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    text-align: justify;
    font-size: 30px;
  }

  .aboutDiv{
    color: rgb(197, 247, 247);
    width: 80%;
    color: #00d6fc;
    margin-bottom: 60px;
    text-align: justify;
    justify-content: flex-start;
    font-size: 30px;
  }
  
  
  .cMain{
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain img{
    width: 100px;
    height: 100px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain div{
    color: rgb(167, 167, 167);
    text-align: center;
    margin-top: -12px;
    margin-bottom: 30px;
    font-size: 22px;
  }
  
  .cLine{
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .beast{
    width: 800px;
    height: 800px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
   }
  
  .price{
    text-align: center;
    color: rgb(0, 250, 167);
    font-size: 28px;
  }
  
  .nftminter2{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: #b8eff8;
    font-size: 80px;
  }
  
  .nftminter2Div{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .center{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .placeAndWL{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 180px;
    margin-top: -250px;  }
  
  /* CSS */
  .button-89 {
    --b: 3px;   /* border thickness */
    --s: .45em; /* size of the corner */
    --color: #ffffff;
    
    padding: calc(.6em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 30px;
  
    border: 0;
  
    -ms-user-select: none;
  
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-89:hover,
  .button-89:focus-visible{
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }
  
  .button-89:active {
    background: var(--color);
    color: #fff;
  }
  
  input{
    border: white 2px solid;
    font-size: 30px;
    padding: 10px;
    margin-right: 22px;
    background-color: rgba(0, 0, 0, 0.432);
    color: #00e1ff;
  }
  
  
  .px-app,
  .px-app *,
  .px-app *::before,
  .px-app *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  .px-glitch {
    text-align: center;
    color: white;
  }
  
  .px-glitchtext {
    font-size: 9rem;
    font-weight: 600;
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    display: block;
  }
  .px-glitchtext-anim {
    -webkit-animation: glitchtext 300ms linear infinite;
            animation: glitchtext 300ms linear infinite;
  }
  @-webkit-keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  @keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  
  .px-glitchsvg {
    font-size: 4rem;
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
  .px-glitchsvg-anim {
    -webkit-animation: glitchsvg 200ms none infinite;
            animation: glitchsvg 200ms none infinite;
  }
  @-webkit-keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  @keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  
  .csPage{
    margin-top: 25vh;
  }
  
  :root {
    --color-bg: #eaeaea;
    --color-loader: #222;
    --loader-size: 100px;
    --loader-speed: 500ms;
  }
  
  .loadMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .loader {
    --loader-height: calc(var(--loader-size) / 2.5);
    display: flex;
    position: relative;
  }
  .loader::before {
    content: '';
    position: absolute;
  }
  .loader::before,
  .loader > span {
    display: block;
    width: var(--loader-height);
    height: var(--loader-height);
    border-radius: 50%;
    background: var(--color-loader);
    background-color: #516466;
    -webkit-animation-name: to-right;
            animation-name: to-right;
    -webkit-animation-duration: var(--loader-speed);
            animation-duration: var(--loader-speed);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .loader > span:nth-child(even) {
    visibility: hidden;
  }
  .loader > span:first-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
  }
  .loader > span:last-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
    animation-direction: reverse;
  }
  
  @-webkit-keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  
  @keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  @-webkit-keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
}

@media screen and (min-width: 3840px) {
  .button-49-2,
  .button-49-2:after {
    width: 600px;
    height: 150px;
    line-height: 88px;
    font-size: 45px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: 0.5s ease-in;
  }
  
  .button-49-2:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49-2:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
    opacity: 0;
  }

  .button-49-2:hover span {
    display:none
  }
  
  .button-49-2:hover:before {
    content:"COMING SOON";
  }
  
  
  .connectMobile {
    display: none;
  }
  .walletAndMint {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .mintP:hover {
    color: gold;
    cursor: pointer;
  }
  
  .btnRed1 {
    margin-right: 40px;
  }
  
  .btnRed2 {
    margin-left: 40px;
  }
  
  :root {
    --primary: #4f5877;
    --secondary: hsl(224, 20%, 17%);
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
  
  .button-49,
  .button-49:after {
    width: 600px;
    height: 150px;
    line-height: 88px;
    font-size: 45px;
    background: linear-gradient(45deg, transparent 5%, #ff013c85 5%);
    border: 0;
    color: #fff;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
  
    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    -webkit-clip-path: var(--slice-0);
            clip-path: var(--slice-0);
  }
  
  .button-49:hover:after {
    -webkit-animation: 1s glitch;
            animation: 1s glitch;
    -webkit-animation-timing-function: steps(2, end);
            animation-timing-function: steps(2, end);
  }
  
  @-webkit-keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @keyframes glitch {
    0% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-20px, -10px);
              transform: translate(-20px, -10px);
    }
  
    10% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(10px, 10px);
              transform: translate(10px, 10px);
    }
  
    20% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    30% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(0px, 5px);
              transform: translate(0px, 5px);
    }
  
    40% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-5px, 0px);
              transform: translate(-5px, 0px);
    }
  
    50% {
      -webkit-clip-path: var(--slice-3);
              clip-path: var(--slice-3);
      -webkit-transform: translate(5px, 0px);
              transform: translate(5px, 0px);
    }
  
    60% {
      -webkit-clip-path: var(--slice-4);
              clip-path: var(--slice-4);
      -webkit-transform: translate(5px, 10px);
              transform: translate(5px, 10px);
    }
  
    70% {
      -webkit-clip-path: var(--slice-2);
              clip-path: var(--slice-2);
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px);
    }
  
    80% {
      -webkit-clip-path: var(--slice-5);
              clip-path: var(--slice-5);
      -webkit-transform: translate(20px, -10px);
              transform: translate(20px, -10px);
    }
  
    90% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(-10px, 0px);
              transform: translate(-10px, 0px);
    }
  
    100% {
      -webkit-clip-path: var(--slice-1);
              clip-path: var(--slice-1);
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @media (min-width: 768px) {
  
    .button-49,
    .button-49:after {
      width: 600px;
      height: 150px;
      line-height: 88px;
    }
  }
  
  .stakePagesBtns {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 20vh;
  }
  .stakePagesBtns2 {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 100px;
  }
  
  
  .stakeMainPage {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .stakeMainPage2 {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/futuristic-gif-9.73bec76a.gif) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .csMAin {
    background: rgb(0,28,32);
    background: linear-gradient(100deg, rgba(0,28,32,1) 0%, rgba(0,24,27,1) 69%, rgba(0,28,32,1) 98%);
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .aboutPageMain {
    background: rgb(0,30,33);
    background: linear-gradient(355deg, rgba(0,30,33,1) 0%, rgba(0,0,0,1) 69%, rgba(0,42,47,1) 98%);
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .stripe-btn {
    --text-shadow-thickness: 0.05ch;
    --text-shadow-negative-thickness: calc(-1 * var(--text-shadow-thickness));
    position: relative;
    border: 2px solid var(--primary);
    font-size: 5rem;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    color: var(--primary);
    text-transform: uppercase;
    text-shadow:
      var(--text-shadow-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-thickness) 0px var(--secondary),
      var(--text-shadow-negative-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary);
    width: 450px;
    text-align: center;
  }
  
  .corners {
    --corner-dimension: 0.7ch;
    --corner-thickness: 3px;
    --corner-negative-thickness: calc(-1 * var(--corner-thickness));
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .corners>i {
    content: "";
    position: absolute;
    width: var(--corner-dimension);
    height: var(--corner-dimension);
  }
  
  .corners>i:nth-child(1) {
    top: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(2) {
    top: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(3) {
    bottom: 0;
    right: 0;
    box-shadow: inset var(--corner-negative-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .corners>i:nth-child(4) {
    bottom: 0;
    left: 0;
    box-shadow: inset var(--corner-thickness) var(--corner-negative-thickness) 0 var(--primary);
  }
  
  .stripes {
    --stripes-position: 0.3ch;
    content: "";
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: calc(100% - var(--stripes-position) * 2);
    width: calc(100% - var(--stripes-position) * 2);
    top: var(--stripes-position);
    left: var(--stripes-position);
    overflow: hidden;
  }
  
  .stripes>i {
    content: "";
    height: calc(100% + 2ch);
    width: 0.5ch;
    background: var(--primary);
    opacity: 0.3;
    -webkit-transform: translateY(-1ch) rotate(37deg);
            transform: translateY(-1ch) rotate(37deg);
    transition: all 0.2s cubic-bezier(0.85, 0, 0.15, 1)
  }
  
  .stripes>i:nth-child(1) {
    transition-delay: 0.02s;
  }
  
  .stripes>i:nth-child(2) {
    transition-delay: 0.04s;
  }
  
  .stripes>i:nth-child(3) {
    transition-delay: 0.06s;
  }
  
  .stripes>i:nth-child(4) {
    transition-delay: 0.08s;
  }
  
  .stripes>i:nth-child(5) {
    transition-delay: 0.10s;
  }
  
  .stripes>i:nth-child(6) {
    transition-delay: 0.12s;
  }
  
  .stripes>i:nth-child(7) {
    transition-delay: 0.14s;
  }
  
  .stripes>i:nth-child(8) {
    transition-delay: 0.16s;
  }
  
  .stripes>i:nth-child(9) {
    transition-delay: 0.18s;
  }
  
  .stripes>i:nth-child(10) {
    transition-delay: 0.20s;
  }
  
  .stripes>i:nth-child(11) {
    transition-delay: 0.22s;
  }
  
  .stripes>i:nth-child(12) {
    transition-delay: 0.24s;
  }
  
  .stripes>i:nth-child(13) {
    transition-delay: 0.26s;
  }
  
  .stripes>i:nth-child(14) {
    transition-delay: 0.28s;
  }
  
  .stripes>i:nth-child(15) {
    transition-delay: 0.3s;
  }
  
  .stripes>i:nth-child(16) {
    transition-delay: 0.32s;
  }
  
  .stripes>i:nth-child(17) {
    transition-delay: 0.34s;
  }
  
  .stripes>i:nth-child(18) {
    transition-delay: 0.36s;
  }
  
  .stripes>i:nth-child(19) {
    transition-delay: 0.38s;
  }
  
  .stripes>i:nth-child(20) {
    transition-delay: 0.4s;
  }
  
  .stripe-btn:hover .stripes>i {
    opacity: 0.5;
  }
  
  .futuristic-button {
    /* Other styling for the button */
    display: inline-block;
    padding: 30px 40px;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.2s ease;
    background-color: #e0706c00;
    font-size: 60px;
    margin-top: 15px;
  }
  
  .futuristic-button:hover {
    background-color: #ff0;
    box-shadow: 0 0 10px #ff0, 0 0 40px #ff0, 0 0 80px #ff0;
    color: #000;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  
  .announcement {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 60vh;
  }
  
  
  .announcementP1 {
    color: gray;
    font-size: 16px;
  }
  
  .announcementP2 {
    color: white;
    font-size: 60px;
  }
  
  /* Animation */
  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    color: gray;
    font-size: 45px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    -webkit-animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 380px;
    }
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 380px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-3 {
    top: 50%;
    color: rgb(0, 194, 219);
    font-size: 40px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tw {
    margin-top: 40px;
  }
  
  .anim-typewriter3 {
    -webkit-animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter3 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @-webkit-keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 780px;
    }
  }
  
  @keyframes typewriter3 {
    from {
      width: 0;
    }
  
    to {
      width: 780px;
    }
  }
  
  .lineP {
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .futuristic-text {
    /* Other styling for the text */
    position: relative;
    overflow: hidden;
  }
  
  .futuristic-text:before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #ff0 0%, #f00 100%);
  }
  
  @-webkit-keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
  
    to {
      width: 100%;
    }
  }
  
  .futuristic-text:after {
    content: '|';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #ff0;
    -webkit-animation: typing 1s steps(40, end);
            animation: typing 1s steps(40, end);
  }
  
  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    letter-spacing: 1.8px;
    width: 280px;
    height: 120px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 40px;
  }
  
  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }
  
  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }
  
  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }
  
  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }
  
  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }
  
  .kave-btn:hover .kave-line:after {
    top: 0;
  }
  
  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }
  
  .header {
    width: 350px;
    height: 162px;
  }
  
  .mintP {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 30px;
    color: white;
    font-size: 27px;
  }
  
  .pic {
    /* 
      width: 25%; 
      position: sticky;
      position: -webkit-sticky;
      left: 100%;
      margin-top: -30%;
      bottom: 0;
      animation: Slide_Left_Right 16.25s ease infinite, Opacity 16.25s ease infinite;*/
    -webkit-transform: rotate(-27deg);
            transform: rotate(-27deg);
    width: 40%;
    position: sticky;
    position: -webkit-sticky;
    margin-top: -30%;
    bottom: 0;
    margin-left: 85%;
    margin-top: -80%;
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  .back {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    z-index: 100000000;
  }
  
  .backDiv {
    z-index: 1000000000000000000;
  }
  
  .blur {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #00000094;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .v2Quote {
    text-align: center;
    color: white;
  
    border: 2px #facc15 solid;
    position: fixed;
    padding: 15px;
    font-size: 30px;
    top: 50%;
    left: 50%;
    z-index: 100000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  
  }
  
  .stakeMainHome {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: rgb(12, 17, 40);
    background: linear-gradient(99deg, rgba(12, 17, 40, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(10, 33, 23, 1) 94%);
  }
  
  .homeBtn {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-between;
    width: 30%;
  }
  
  .homeBtn button {
    color: black;
    background-color: #facc15;
    width: 200px;
    padding: 20px;
  
  }
  
  .homeBtn button:hover {
    margin-bottom: 2%;
  }
  
  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 2%;
  }
  
  .homeImages1 {
    -webkit-animation: Slide_Down1 6s ease infinite;
            animation: Slide_Down1 6s ease infinite;
  }
  
  .homeImages2 {
    -webkit-animation: Slide_Down2 5s ease infinite;
            animation: Slide_Down2 5s ease infinite;
  }
  
  @-webkit-keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down1 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @-webkit-keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  @keyframes Slide_Down2 {
    0% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
      opacity: 1;
    }
  
    50% {
      -webkit-transform: translateY(20%);
              transform: translateY(20%);
    }
  
    100% {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
  
  }
  
  
  .right {
    width: 20%;
    margin-top: -70%;
  }
  
  .homeLogo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
  
  .submitBtn {
    background-color: #44a06c;
    color: white;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .buyDetails2 {
    border: 3px black solid;
    width: 50%;
    padding: 10px;
  }
  
  .emailSectionH {
    color: black;
  
    font-size: 20px;
    margin-bottom: 10px;
    color: #facc15;
  }
  
  .emailSection {
    color: #facc15;
    background: rgba(255, 255, 255, 0.096);
    border: 2px dashed #facc15;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .emailSection2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    flex-flow: column;
  }
  
  .prizeBtn {
    background-color: #facc15;
    color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    width: 150px;
    box-shadow: 0px 2px #a58503;
  }
  
  .prizeBtnDis {
    background-color: #8f8767;
    color: rgb(61, 61, 61);
    border: none;
    width: 150px;
    box-shadow: 0px 2px #978e6b;
    cursor: no-drop;
  }
  
  .step {
    color: white;
    font-size: 15px;
  }
  
  .connect2Mob {
    display: none;
  }
  
  .tableMobile {
    display: none;
  }
  
  .number {
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    padding: 10px;
    width: 300px;
    text-align: center;
  }
  
  .formBtn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .adminBtns button {
    width: 300px;
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    font-weight: 700;
    color: #ffffff;
    background-color: #115dce00;
    border-radius: 10px;
    border: 2px rgb(59, 131, 240) solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
  }
  
  .sentOrders {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    background-color: #3b3b3b00;
    width: 60%;
    padding: 10px;
  
  }
  
  .view2 {
    margin-left: 5%;
    width: 20%;
  }
  
  .or {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .view {
    margin-right: 5%;
  }
  
  .adminBtnsMain {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-top: 5%;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid #d79f60;
  }
  
  .orders {
    margin-top: 1%;
  }
  
  
  
  .t1 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 5%;
    text-decoration: underline;
    font-weight: 500;
  }
  
  table {
    color: white;
    border-collapse: collapse;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 5%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  
  }
  
  .hideTable {
    display: none;
  }
  
  .adminBtns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  
  .adminBtns button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  
  
  .buyDetails {
    color: white;
    font-size: 25px;
    text-align: left;
    margin-left: 3%;
    margin-top: 5%;
  }
  
  .btnsMainMob {
    display: none;
  }
  
  .stakeWC {
    background-color: gold;
    border-style: none;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .stakeWCMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .home {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .home:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakeWC:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .navBarStake {
    justify-content: center;
    margin-bottom: 2%;
  
  }
  
  .navBarStake2 {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    display: flex;
  
  }
  
  .triangleTopRight {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent #f3ba2f transparent transparent;
    position: sticky;
    position: -webkit-sticky;
    bottom: 80%;
    left: 90%;
    margin-top: -70%;
  }
  
  .v1 {
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 90%;
    left: 120%;
    margin-top: -70%;
    display: inline-block;
    z-index: 100000;
  }
  
  
  .btns {
    z-index: 10000;
    width: 400px;
    background-color: #586285;
    color: white;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: ease-in 0.5s;
    font-size: 45px;
  }
  
  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns2 {
    width: 400px;
    color: white;
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #586285;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
    transition: ease-in 0.5s;
    font-size: 45px;

  }
  
  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .btns:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btns2:hover {
    background-color: #29cdfe;
    color: blue;
  }
  
  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }
  
  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    border: 6px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #60e1f8, #006c80);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background: url(/static/media/Container.64f3b4d9.png) no-repeat center center fixed;
    background-size: cover;
    padding: 30px;
  }
  
  .markBarCon {
    background: rgba(172, 172, 172, 0.158);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 30px;
    -webkit-clip-path: polygon(17% 0, 100% 0, 100% 73%, 85% 100%, 0 100%, 0 28%);
            clip-path: polygon(17% 0, 100% 0, 100% 73%, 85% 100%, 0 100%, 0 28%);
  }
  
  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }
  
  .num {
    font-size: 50px;
    color: #bdf1f5;
    text-align: center;
  }
  
  .name {
    color: #bdf1f5;
    font-size: 40px;
    text-align: center;
  
  }
  
  .num2 {
    font-size: 30px;
    color: white;
  
    text-align: center;
  }
  
  .name2 {
    color: white;
    font-size: 25px;
    text-align: center;
    font-weight: 500;
  }
  
  .markBar img {
    width: 150px;
    height: 150px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }
  
  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7426568039325105) 0%, rgba(0, 0, 0, 0.9107240308232668) 74%, rgba(0, 0, 0, 0.9555419579941351) 100%), url(/static/media/IuNL.e000bf3c.gif) no-repeat center center fixed;
    background-size: cover;
  }
  
  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  
  }
  
  .sticky {
    width: 20%;
  
    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }
  
  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .board {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 70px;
    border: #ffffff 4px solid;
    box-shadow: -10px 10px rgba(75, 75, 75, 0.842);
    width: 1000px;
    margin-left: 1%;
    margin-right: 1%;
  
  }
  
  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
   }
  
  .nameStake {
    width: 50%;
  }
  
  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .boardImg {
    background-color: #ffb963;
  
  }
  
  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }
  
  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }
  
  .approveBtns1 {
    border: 4px solid;
    background-color: rgba(206, 8, 8, 0);
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 35px;
  
    width: 600px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  
  }
  
  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);
  
    padding: 20px;
    font-size: 23px;
  
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;
  
    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
  
  }
  
  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .itemsDiv {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 40%;
  }
  
  .itemImg {
    width: 400px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg2 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg3 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg4 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemImg5 {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .itemName {
    color: white;
    font-weight: 500;
    text-align: center;
    padding-top: 2%;
    font-size: 20px;
    background-color: #000000;
  }
  
  .buyBtn {
    background-color: #000000;
    padding-top: 2%;
    padding-bottom: 3%;
  
  }
  
  .buy {
    background-color: #d79f60;
    color: rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  .buy:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
  
  .logo2 {
    width: 20%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: #ffffff;
    color: #487a17;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
  
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
  
  
  .rmPic img {
    width: 100%;
    background-size: cover;
  }
  
  .rmMain {
    margin-top: 10%;
  }
  
  .unstakeImgM {
    display: none;
  }
  
  .formStake {
    z-index: 1;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .allWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }
  
  .light {
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .mintMain {
    background: linear-gradient(100deg, rgba(11,18,32,1) 0%, rgba(0,7,20,0.7314523221397935) 69%, rgba(11,18,32,1) 98%), url(/static/media/grid_0.a399d903.png) no-repeat center center fixed;
    background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  .light2 {
    width: 100%;
    background-color: #000000;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }
  
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    z-index: 1;
    font-family: "Third Rail - Demo";
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff9900;
  }
  
  .in2 {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    z-index: 1;
    justify-content: center;
  }
  
  .parrot {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: #32743b7a -15px 15px;
    border-radius: 20px;
  }
  
  
  @-webkit-keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    margin-bottom: 70px;
    box-shadow: 0 0 20px #00d6fc;
    margin-top: -130px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
      }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 35px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .successfully {
    margin-top: 1%;
    font-size: 35px;
    color: #000000;
    text-align: center;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 35px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  
  }
  
  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #67c4b6;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8fc7bf;
  }
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3%;
  }
  
  .connect div {
    margin-left: 17px;
    margin-right: 17px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  #fontSize {
    font-size: 40px;
    color: white;
    z-index: 1;

  }
  
  #fontSize:hover {
    color: #00E6F6;
    cursor: pointer;
    transition: ease 0.5s;
  }
  
  #fontSizeActive {
    font-size: 40px;
    color: #00E6F6;
   }
  
  #fontSizeActive:hover {
    color: white;
    cursor: pointer;
    transition: ease 0.5s;
    
  }
  
  
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    height: 80px;
    width: 80px;
    border: none;
    cursor: pointer;
    background-color: #4bbdff;
  }
  
  .btnfos-0-2-2 {
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    height: 80px;
    width: 80px;
    border: none;
    background-color: #4bbdff;
  
    cursor: pointer;
  
  }
  
  .btnfos-0-2-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-2:hover{
    border: 2px solid #00a2ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #85d2ff;
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 10px;
    border: none;
    width: 200px;
    border-top-left-radius: 10px;
   }
  
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #004dff;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0   var(--c),
      calc(-1 * -0.08em)  .01em 2px #0004;
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }
  
  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }
  
  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }
  
  .button-92 {
    font-weight: bold;
    font-size: 3rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
    color: #44a06c;
    font-size: 80px;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    z-index: 1;
  
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #00FF00
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;
  
    /* border-left: #2d363600;
    border-right: #2d363600;*/
  }
  
  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-top: -180px;

  }
  
  .wallet2 {
    background-color: #d79f6000;
    color: rgb(0, 0, 0);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: white 2px solid;
    font-weight: 700;
    transition: ease 0.5s;
  
  }
  
  .wallet2:hover {
    background-color: rgb(0, 0, 0);
    color: #d79f60;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    z-index: 10000;
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  
  }
  
  .lookDown {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  .wallet3::after {
    transition: all 0.8s
  }
  
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: #00E6F6;
    padding-bottom: 5%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    position: static;
    margin-bottom: 5%;
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  
  
  .copyright2 {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .images_show {
    width: 90%;
  }
  
  .hide {
    display: none;
  }
  
  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }
  
  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Comic Kings', sans-serif;
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .imagesAndBtn {
    justify-content: center;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }
  
  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  
  }
  
  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }
  
  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  
  .formMain1-1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }
  
  .ownNFT {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
  
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .discordB {
    display: none;
  }
  
  .medal {
    width: 35px;
    height: 35px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .stakebtn {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn2 {
    padding: 15px;
    font-size: 15px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: 700;
    color: #ffffff;
    background-color: #ce5d11;
    border-radius: 50px;
    border: none;
    box-shadow: #b15411 0px 5px;
  }
  
  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .stakebtnDiv {
    z-index: 10000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoPic {
    width: 110px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }
  
  .logoPic2 {
    border-radius: 50%;
    width: 10%;
    border: 1px solid #39a7f1;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #1e98e9;
  }
  
  .logo {
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .btnT {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2%;
    margin-top: 5%;
  }
  
  * {
    box-sizing: border-box;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #e89d38;
    padding-left: 100%;
    margin-top: 5%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }
  
  .boxWrapC {
    margin-top: 10%;
  }
  
  .mainTB {
    text-align: center;
    color: white;
  
    font-size: 50px;
  
  }
  
  .mainTBP {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .sections3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #64a81f;
    border-radius: 50px;
    border: 2px white solid;
    padding: 10px;
  }
  
  .sT {
    text-align: center;
    color: white;
  
    font-size: 25px;
    margin-bottom: 1%;
  }
  
  .sT2 {
    text-align: center;
    color: white;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .footer2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10%;
  }
  
  .footer2 img {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  
  .footer2 img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .tName {
    color: white;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    font-size: 50px;
  
    cursor: pointer;
  }
  
  .stakeT {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 45px;
  
    letter-spacing: 2px;
  }
  
  .winnerSection {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .winnerT {
    text-align: center;
    color: #facc15;
  
    font-size: 50px;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
  }
  
  table {
    border-collapse: collapse;
    width: 80%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #1f1f1f;
    color: #facc15;
  }
  
  .bg {
    background-color: #facc15;
    color: black;
  }
  
  
  
  .imageSet {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .imageSet img {
    width: 30%;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
  }
  
  .imageSet img:hover {
    width: 30%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .logoNFT {
    width: 250px;
    height: 250px;
    z-index: 1;

  }
  
  .glitch {
    color: white;
    font-size: 350px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  
  @-webkit-keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  
  @keyframes noise-anim {
    0% {
      clip: rect(66px, 9999px, 49px, 0);
    }
    5% {
      clip: rect(4px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 58px, 0);
    }
    15% {
      clip: rect(94px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(94px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(74px, 9999px, 91px, 0);
    }
    30% {
      clip: rect(75px, 9999px, 29px, 0);
    }
    35% {
      clip: rect(91px, 9999px, 21px, 0);
    }
    40% {
      clip: rect(47px, 9999px, 88px, 0);
    }
    45% {
      clip: rect(42px, 9999px, 47px, 0);
    }
    50% {
      clip: rect(87px, 9999px, 98px, 0);
    }
    55% {
      clip: rect(22px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(3px, 9999px, 76px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(80px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(61px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(2px, 9999px, 48px, 0);
    }
    85% {
      clip: rect(18px, 9999px, 46px, 0);
    }
    90% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    95% {
      clip: rect(78px, 9999px, 35px, 0);
    }
    100% {
      clip: rect(63px, 9999px, 60px, 0);
    }
  }
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  @-webkit-keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  
  @keyframes noise-anim-2 {
    0% {
      clip: rect(60px, 9999px, 88px, 0);
    }
    5% {
      clip: rect(100px, 9999px, 75px, 0);
    }
    10% {
      clip: rect(90px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 80px, 0);
    }
    20% {
      clip: rect(49px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(67px, 9999px, 73px, 0);
    }
    30% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    35% {
      clip: rect(4px, 9999px, 13px, 0);
    }
    40% {
      clip: rect(32px, 9999px, 46px, 0);
    }
    45% {
      clip: rect(38px, 9999px, 49px, 0);
    }
    50% {
      clip: rect(23px, 9999px, 90px, 0);
    }
    55% {
      clip: rect(31px, 9999px, 99px, 0);
    }
    60% {
      clip: rect(9px, 9999px, 92px, 0);
    }
    65% {
      clip: rect(11px, 9999px, 53px, 0);
    }
    70% {
      clip: rect(97px, 9999px, 61px, 0);
    }
    75% {
      clip: rect(69px, 9999px, 1px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 42px, 0);
    }
    85% {
      clip: rect(88px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(59px, 9999px, 58px, 0);
    }
    95% {
      clip: rect(90px, 9999px, 49px, 0);
    }
    100% {
      clip: rect(75px, 9999px, 15px, 0);
    }
  }
  .glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    text-align: center;
  
  }
  
  .aboutPageMain2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .aboutP{
    color: rgb(197, 247, 247);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    text-align: justify;
    font-size: 45px;
  }

  .aboutDiv{
    color: rgb(197, 247, 247);
    width: 80%;
    color: #00d6fc;
    margin-bottom: 60px;
    text-align: justify;
    justify-content: flex-start;
    font-size: 45px;
  }
  
  
  .cMain{
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain img{
    width: 200px;
    height: 200px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .cMain div{
    color: rgb(167, 167, 167);
    text-align: center;
    margin-top: -12px;
    margin-bottom: 30px;
    font-size: 35px;
  }
  
  .cLine{
    background-color: rgba(99, 99, 99, 0.452);
    width: 100%;
    height: 1px;
  }
  
  .beast{
    width: 800px;
    height: 800px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
   }
  
  .price{
    text-align: center;
    color: rgb(0, 250, 167);
    font-size: 28px;
  }
  
  .nftminter2{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: #b8eff8;
    font-size: 80px;
  }
  
  .nftminter2Div{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .center{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .placeAndWL{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 180px;
    margin-top: -250px;  }
  
  /* CSS */
  .button-89 {
    --b: 3px;   /* border thickness */
    --s: .45em; /* size of the corner */
    --color: #ffffff;
    
    padding: calc(.6em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 30px;
  
    border: 0;
  
    -ms-user-select: none;
  
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-89:hover,
  .button-89:focus-visible{
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }
  
  .button-89:active {
    background: var(--color);
    color: #fff;
  }
  
  input{
    border: white 2px solid;
    font-size: 30px;
    padding: 10px;
    margin-right: 22px;
    background-color: rgba(0, 0, 0, 0.432);
    color: #00e1ff;
  }
  
  
  .px-app,
  .px-app *,
  .px-app *::before,
  .px-app *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  .px-glitch {
    text-align: center;
    color: white;
  }
  
  .px-glitchtext {
    font-size: 16rem;
    font-weight: 600;
    text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    display: block;
  }
  .px-glitchtext-anim {
    -webkit-animation: glitchtext 300ms linear infinite;
            animation: glitchtext 300ms linear infinite;
  }
  @-webkit-keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  @keyframes glitchtext {
    0% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
    20% {
      text-shadow: 0 0 0 rgba(255, 0, 255, 1), -3px -1px 0 rgba(0, 255, 255, 1);
    }
    40% {
      text-shadow: -3px -2px 0 rgba(255, 0, 255, 1),
        2px 1px 0 rgba(0, 255, 255, 1);
    }
    60% {
      text-shadow: -1px 2px 0 rgba(255, 0, 255, 1), 0 0 0 rgba(0, 255, 255, 1);
    }
    80% {
      text-shadow: 2px -1px 0 rgba(255, 0, 255, 1), 3px 2px 0 rgba(0, 255, 255, 1);
    }
    100% {
      text-shadow: 3px 0 0 rgba(255, 0, 255, 1), -3px 0 0 rgba(0, 255, 255, 1);
    }
  }
  
  .px-glitchsvg {
    font-size: 4rem;
    -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
            filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
      drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
  }
  .px-glitchsvg-anim {
    -webkit-animation: glitchsvg 200ms none infinite;
            animation: glitchsvg 200ms none infinite;
  }
  @-webkit-keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  @keyframes glitchsvg {
    0% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
    20% {
      -webkit-filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(0 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px -1px 0 rgba(0, 255, 255, 1));
    }
    40% {
      -webkit-filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-2px -2px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 1px 0 rgba(0, 255, 255, 1));
    }
    60% {
      -webkit-filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(-1px 2px 0 rgba(255, 0, 255, 1))
        drop-shadow(0 0 0 rgba(0, 255, 255, 1));
    }
    80% {
      -webkit-filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px -1px 0 rgba(255, 0, 255, 1))
        drop-shadow(2px 2px 0 rgba(0, 255, 255, 1));
    }
    100% {
      -webkit-filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
              filter: drop-shadow(2px 0 0 rgba(255, 0, 255, 1))
        drop-shadow(-2px 0 0 rgba(0, 255, 255, 1));
    }
  }
  
  .csPage{
    margin-top: 25vh;
  }
  
  :root {
    --color-bg: #eaeaea;
    --color-loader: #222;
    --loader-size: 100px;
    --loader-speed: 500ms;
  }
  
  .loadMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .loader {
    --loader-height: calc(var(--loader-size) / 1.5);
    display: flex;
    position: relative;
  }
  .loader::before {
    content: '';
    position: absolute;
  }
  .loader::before,
  .loader > span {
    display: block;
    width: var(--loader-height);
    height: var(--loader-height);
    border-radius: 50%;
    background: var(--color-loader);
    background-color: #516466;
    -webkit-animation-name: to-right;
            animation-name: to-right;
    -webkit-animation-duration: var(--loader-speed);
            animation-duration: var(--loader-speed);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .loader > span:nth-child(even) {
    visibility: hidden;
  }
  .loader > span:first-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
  }
  .loader > span:last-child {
    -webkit-animation-name: scale-up;
            animation-name: scale-up;
    animation-direction: reverse;
  }
  
  @-webkit-keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  
  @keyframes to-right {
    to {
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
  }
  @-webkit-keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
}
